import { useEffect } from "react";
import { auth, universalLogin } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "utils/auth";

export const RegsiterUser = ({ children }: any) => {
	const { isUserSession, SET_USER_SESSION } = SESSION_AUTH()
	const [user]: any = useAuthState(auth);

	useEffect(() => {
		if (user && !isUserSession?.userId) {
			universalLogin(user).then((res: any) => {
				if(res.data.code !== 600) SET_USER_SESSION(res.data)
			})
		}
	}, [isUserSession?.userId, user])

	return <>{children}</>
}