import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { toast } from "react-toastify";
import baseApi from "api/baseApi";
import { DELETE_COOKIES, SESSION_AUTH } from "utils/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA7yb0AANWCaBiIDk5kpH-lyQeHeOtcLgM",
  authDomain: "authentication.6ix.com",
  projectId: "six-events",
  storageBucket: "six-events.appspot.com",
  messagingSenderId: "215382211252",
  appId: "1:215382211252:web:b3bbf8418e33745faf75e1",
  measurementId: "G-YGEEQET0LL",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.universal = {};
getAnalytics(app);
const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      toast("You are now logged in ");
      return auth.currentUser;
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithCredentialResponse = async (response) => {
  if (response) {
    try {
      const cred = GoogleAuthProvider.credential(response.credential);
      await signInWithCredential(auth, cred);
      if (auth.currentUser) {
        toast("You are now logged in");
        return auth.currentUser;

      } else {
        toast("Error:  Login faild");
        return false;
      }
    } catch (err) {
      toast(errorSplit(err.message));
      return false;
    }
  } else {
    toast(errorSplit("Something went wrong try again"));
    return false;
  }
};


const signInWithUserEmailLink = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithUserEmailLinkVerify = (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        if (auth.currentUser) {
          toast("You are now logged in");
          return auth.currentUser;
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } else {
    toast("Error:  Something went wrong");
    return false;
  }
};
const userLogout = async (isUserSession) => {
  signOut(auth);

  try {
    if (isUserSession?.userId) {
      const res = await baseApi.post('/api/v1/universallogout', {
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
      })

      if (res.data.code === 200 || res.data.code === 600) {
        toast.success('Logout Successful')
        DELETE_COOKIES();

        return res.data
      }
      else {
        DELETE_COOKIES();

        return Promise.reject(res)
      }
    } else {
      DELETE_COOKIES();
      console.log('error not logout')
      
      return Promise.reject('error')
    }
  } catch (err) {
    DELETE_COOKIES();
    
    return Promise.reject(err)
  }
};

const universalLogin = (user) =>
  baseApi.post('/api/login', {
    tokenID: user?.accessToken,
    email: user?.email
  }).then((res) => {
    if (res.status === 200) {
      auth.universal = res.data.data
      return res.data
    } else {
      userLogout()
      return Promise.reject(res)
    }
  }).catch((err) => {
    userLogout()
    return Promise.reject(err)
  })

const getOrgList = (user) =>
  baseApi.post('/api/orglists', {
    userId: user?.userId,
    org: user?.org?.id,
  }).then((res) => res).catch((err) => Promise.reject(err))

const switchOrgList = (user, orgId) =>
  baseApi.post('/api/switchorg', {
    userId: user?.userId,
    org: user?.org?.id,
    switch: orgId
  }).then((res) => res).catch((err) => Promise.reject(err))

const createOrg = (user) =>
  baseApi.post('/api/createorg', {
    userId: user?.userId,
    org: user?.org?.id,
    switch: user?.org?.id
  }).then((res) => res).catch((err) => Promise.reject(err))


const errorSplit = (error) => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
  signInWithCredentialResponse,
  universalLogin,
  getOrgList,
  switchOrgList,
  createOrg,
};
