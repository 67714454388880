import { useContext, useEffect, useState } from "react";
import { AppContext } from "context";
import baseApi from "api/baseApi";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DraftWrapper, Wrapper, ImageHolder, Heading } from "./style";
import EditNoteIcon from '@mui/icons-material/EditNote';

const Draft = () => {
  const navigate = useNavigate();
  const { org } = useContext(AppContext)
  const [drafts, setDrafts] = useState([]);

  useEffect(() => {
    if(org)
      baseApi.get(`/api/draftcampaign/${org.id}`)
        .then((res) => setDrafts(res.data))
        .catch((err) => console.log(err))
  }, [org])

  return (
    <div className="sixads-content">
      <div className="sixads-adss">
        <DraftWrapper>
          {drafts.length > 0 ?
          drafts.map((draft: any) =>
            <div>
              <Wrapper onClick={() => navigate(`/lead/${draft?.uuid}`)}>
                <ImageHolder>
                  <EditNoteIcon />
                </ImageHolder>
                <Heading
                  variant="h3"
                >
                  {draft?.campaignname}
                </Heading>
              </Wrapper>
            </div>
          ) : 
          <Heading variant="h3">No Draft Saved</Heading>
        }
        </DraftWrapper>
      </div>
    </div>
  );
};


export default Draft;