import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const MeetRequestIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="30" rx="6" fill="#2B2B2B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97631 17.3095C8.60143 16.6844 9.44928 16.3332 10.3333 16.3332H15.6667C16.5507 16.3332 17.3986 16.6844 18.0237 17.3095C18.6488 17.9346 19 18.7825 19 19.6665V20.9999C19 21.368 18.7015 21.6665 18.3333 21.6665C17.9651 21.6665 17.6667 21.368 17.6667 20.9999V19.6665C17.6667 19.1361 17.456 18.6274 17.0809 18.2523C16.7058 17.8772 16.1971 17.6665 15.6667 17.6665H10.3333C9.8029 17.6665 9.29419 17.8772 8.91912 18.2523C8.54405 18.6274 8.33333 19.1361 8.33333 19.6665V20.9999C8.33333 21.368 8.03486 21.6665 7.66667 21.6665C7.29848 21.6665 7 21.368 7 20.9999V19.6665C7 18.7825 7.35119 17.9346 7.97631 17.3095Z" fill="#E3C050"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9993 9.66652C11.8948 9.66652 10.9993 10.562 10.9993 11.6665C10.9993 12.7711 11.8948 13.6665 12.9993 13.6665C14.1039 13.6665 14.9993 12.7711 14.9993 11.6665C14.9993 10.562 14.1039 9.66652 12.9993 9.66652ZM9.66602 11.6665C9.66602 9.82557 11.1584 8.33319 12.9993 8.33319C14.8403 8.33319 16.3327 9.82557 16.3327 11.6665C16.3327 13.5075 14.8403 14.9999 12.9993 14.9999C11.1584 14.9999 9.66602 13.5075 9.66602 11.6665Z" fill="#E3C050"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6873 16.9201C19.7794 16.5636 20.143 16.3492 20.4995 16.4413C21.2146 16.6259 21.8482 17.0428 22.3006 17.6265C22.7531 18.2102 22.999 18.9277 22.9995 19.6663L22.9995 21.0001C22.9995 21.3683 22.701 21.6667 22.3328 21.6667C21.9647 21.6667 21.6662 21.3683 21.6662 21.0001V19.6672C21.6662 19.6672 21.6662 19.6673 21.6662 19.6672C21.6658 19.2242 21.5183 18.7936 21.2469 18.4434C20.9754 18.0932 20.5952 17.843 20.1662 17.7322C19.8097 17.6402 19.5953 17.2766 19.6873 16.9201Z" fill="#E3C050"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.021 8.92139C17.1123 8.56471 17.4755 8.34959 17.8322 8.44092C18.5492 8.6245 19.1847 9.0415 19.6386 9.62617C20.0924 10.2108 20.3387 10.9299 20.3387 11.6701C20.3387 12.4102 20.0924 13.1293 19.6386 13.714C19.1847 14.2987 18.5492 14.7157 17.8322 14.8992C17.4755 14.9906 17.1123 14.7755 17.021 14.4188C16.9297 14.0621 17.1448 13.6989 17.5015 13.6076C17.9317 13.4974 18.313 13.2472 18.5853 12.8964C18.8576 12.5456 19.0054 12.1142 19.0054 11.6701C19.0054 11.226 18.8576 10.7945 18.5853 10.4437C18.313 10.0929 17.9317 9.84273 17.5015 9.73258C17.1448 9.64126 16.9297 9.27807 17.021 8.92139Z" fill="#E3C050"/>
    </SvgIcon>
  );
};