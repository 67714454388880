
import { createContext, useEffect, useMemo, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import baseApi from "api/baseApi";
import { toast } from "react-toastify";

export const AppContext = createContext<any>(undefined)

export const Context = ({ children }: any) => {
	const [balance, setBalance] = useState(0);
	const [universalData, setUniversalData] = useState<any>();
	const [userInfo, setUserInfo] = useState();
	const [refetch, setRefetch] = useState(false);

	const org = useMemo(() => universalData?.org, [universalData])

	const updateUsername = (user: any) => {
		setUserInfo(user)
	}

	const updateBalance = (value: any) => {
		setRefetch(!refetch)
	};

	useEffect(() => {
		if (universalData && org) {
			baseApi.post('/api/wallet/balance', {
				OrgName: org.id,
			}).then((res: any) => setBalance(res.data.balance))
				.catch((err) => {
					toast.error(err.response.data.error)
				})
		}
	}, [universalData, org, refetch])

	// useEffect(() => {
	// 	if (user) {
	// 		updateOrgList()
	// 	}
	// }, [user])

	// useEffect(() => {
	// 	if (!localStorage.getItem('org') || localStorage.getItem('org')?.includes('undefined')) {
	// 		if (orgList) {
	// 			updateOrg(orgList[0])
	// 		}
	// 	} else {
	// 		try {
	// 			const data = localStorage.getItem('org') ? JSON.parse(localStorage.getItem('org') || '') : ''
	// 			updateOrg(data)
	// 		}
	// 		catch { }
	// 	}
	// }, [orgList])

	return (
		<AppContext.Provider value={{ balance, updateBalance, org, universalData, setUniversalData, userInfo, updateUsername }}>
			{children}
		</AppContext.Provider>
	);
}