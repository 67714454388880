import { Controller, useFieldArray, useForm } from "react-hook-form";
import infoIcon from "../../assets/images/icon-info.svg";
import logonsix from "../../assets/images/6ixn-logo.svg";
import { FormCol, FormField, FormGroup, FormHeading, FormRow, FormWrapper, HeadingHead, LabelHeading, LineSeparator, LogoWrap, RowHeading, WidgetBody, WidgetBox, WidgetHeading, Wrapper, IconGroupsWrapper, IconControlsGroup, IconRadioLabel, CustomDivider, FieldsColumnsWrapper, FieldCol, HiddenSeparator, FormSelectField, RangeSlider, ChecksGroupsWrapper, BoxRadioLabel, BoxControlsGroup, ButtonAdd, DatePickerWrapper, CustomDatepicker, ButtonLink, FundsDetailWrapper, TextLine, TextPrice, StatsTable, ButtonPrimary, ButtonWrapper, FundsModal, ModalContent, ModalHeading, ButtonDefault, ModalFoot, IconsWrapper, IconItem, ErrorText, ButtonRemove } from "./style";
import { FormControl, Radio, MenuItem, Select, Checkbox, Box, Chip, TextField, TableBody, TableCell, TableRow, TableFooter, Autocomplete, Button, ButtonGroup } from "@mui/material";
import { ClicksIcon } from "components/Icons/ClicksIcon";
import { ImpressionsIcon } from "components/Icons/ImpressionsIcon";
import { MeetRequestIcon } from "components/Icons/MeetRequestIcon";
import { DownloadPresentationIcon } from "components/Icons/DownloadPresentationIcon";
import { IIIPageViewsIcon } from "components/Icons/IIIPageViewsIcon";
import { OtherGoalsIcon } from "components/Icons/OtherGoalsIcon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PlusIcon } from "components/Icons/PlusIcon";
import { ArrowRightIcon } from "components/Icons/ArrowRightIcon";
import baseApi from "api/baseApi";
import { CrossIcon } from "components/Icons/CrossIcon";
import { DesignPreview } from "./DesignPreview";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Country } from 'country-state-city';
import { StripeForm } from "components/StripeForm";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { AppContext } from "context";
import { TagAutocomplete } from "./TagAutocomplete";
import { leadBuilder, usdDollar } from "utils/helper";
import { LocationTag } from "./LocationTag";


function valuetext(value: any) {
  return `${value}°C`;
}

const MetaForm = ({ data }: any) => {
  const [saving, setSaving] = useState(false);
  const { register, handleSubmit, watch, control, formState: { errors }, getValues } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...data,
      age: data?.age?.split('-')
    }
  });
  const campaignBudget = watch("budget", 0);
  const { universalData, balance, org, updateBalance } = useContext(AppContext)
  const navigate = useNavigate();
  const exRef = useRef<any>();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const names = Country.getAllCountries().map((country) => country.name)

  const languages = [
    "Arabic", "Bengali", "Bulgarian", "Catalan", "Chinese (Simplified)", "Chinese (Traditional)", "Croatian", "Czech", "Danish", "Dutch", "English (Australia, United Kingdom, and United States)", "Estonian", "Filipino", "Finnish", "French", "German", "Greek", "Gujarati", "Hebrew", "Hindi", "Hungarian", "Icelandic", "Indonesian", "Italian", "Japanese", "Kannada", "Korean", "Latvian", "Lithuanian", "Malay", "Malayalam", "Marathi", "Norwegian", "Persian", "Polish", "Portuguese (Brazil and Portugal)", "Punjabi", "Romanian", "Russian", "Serbian", "Slovak", "Slovenian", "Spanish (Latin American and European)", "Swedish", "Tamil", "Telugu", "Thai", "Turkish", "Ukrainian", "Urdu", "Vietnamese"
  ]

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const { fields: headField, append: headAppend, remove: headRemove } = useFieldArray({
    control,
    name: "Headline"
  });

  const { fields: descriptionField, append: descriptionAppend, remove: descriptionRemove } = useFieldArray({
    control,
    name: "Description"
  });

  const onSubmit = async (data: any) => {
    setSaving(true)
    baseApi.post('/api/lead-form/', {
      ...leadBuilder(data),
      email: universalData?.email,
      orgName: org.id,
      orgCode: org.orgName
    })
      .then((res) => {
        navigate('/campaign-dashboard')
        updateBalance()
        toast.success(res.data.message)
      })
      .catch((err) => toast.error(err.data.message))
      .finally(() => setSaving(false))
  }

  const onSave = async (draftData: any) => {
    setSaving(true)
    baseApi.post('/api/save', {
      ...draftData,
      age: draftData?.age?.join('-') || "18-65",
      email: universalData?.email,
      orgName: org.id,
      orgCode: org.orgName
    }
    ).then((res) => toast.success(res.data.message))
      .catch((err: any) => toast.error(err.data.message))
      .finally(() => setSaving(false))
  }

  useEffect(() => {
    if (headField.length === 0) {
      headAppend('')
      descriptionAppend('')
    }
  }, [])

  return (
    <FormWrapper className="lead-form">
      <Wrapper>
        <HeadingHead>
          <LogoWrap>
            <img src={logonsix} alt="6ix leads" width={"109"} height={"60"} />
          </LogoWrap>
          <LineSeparator orientation="vertical" />
          <FormHeading
            variant="h1"
          >
            Keyword Campaign
          </FormHeading>
        </HeadingHead>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormCol>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                How will you refer to your campaign internally?
              </WidgetHeading>
              <WidgetBody>
                <FormGroup
                  className={
                    errors.campaignname && 'form--error'
                  }
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Internal Campaign Name
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <FormField id="ldf-Name" {...register('campaignname', { required: "Campaign name is required" })} hiddenLabel />
                  {errors.campaignname &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.campaignname.message}
                    </ErrorText>
                  }
                </FormGroup>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                What do you want people to see?
              </WidgetHeading>
              <WidgetBody>
                <FormGroup
                  className={
                    errors.DestinationURL && 'form--error'
                  }
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Destination URL
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <FormField id="ldf-Destination" {...register('DestinationURL', { required: "Destination is required" })} hiddenLabel />
                  {errors.DestinationURL &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.DestinationURL.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={
                    errors.Headline && 'form--error'
                  }
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Headline
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  {headField.map((item, index) => (
                    <div key={item.id} className="field-wrapper">

                      <FormField id="ldf-Headline" inputProps={{ maxLength: 30 }} {...register(`Headline.${index}.name`, { required: "Headline is required", maxLength: { value: 30, message: "maxlength exceeded" } })} hiddenLabel />
                      <div style={{ textAlign: "right", position: "absolute", right: "5px", bottom: "-16px", fontSize: "12px", lineHeight: "1.1" }}>
                        {`${watch().Headline?.[index]?.name?.length}/30`}
                      </div>
                      {errors.Headline?.[index]?.name &&
                        <ErrorText
                          variant="caption"
                          className="no-space"
                        >
                          {errors.Headline?.[index]?.name.message}
                        </ErrorText>
                      }
                      {index !== 0 &&
                        <ButtonRemove onClick={() => {
                          headRemove(index)
                          descriptionRemove(index)
                        }} ><CrossIcon /></ButtonRemove>
                      }
                    </div>
                  ))}
                  {/* {watch('Headline')?.length < 4 && (
                    < ButtonAdd disabled={watch('Headline')?.slice(-1)[0].name === ''}
                      onClick={() => headAppend('')}>
                      Add Headline
                    </ButtonAdd>
                  )
                  } */}

                </FormGroup>
                <FormGroup
                  className={
                    errors.Description && 'form--error'
                  }
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Description
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  {descriptionField.map((item, index) => (
                    <div key={item.id} className="field-wrapper">

                      <FormField id="ldf-Headline" inputProps={{ maxLength: 90 }} {...register(`Description.${index}.name`, { required: "Description is required", maxLength: { value: 90, message: "maxlength exceeded" } })} hiddenLabel />
                      <div style={{ textAlign: "right", position: "absolute", right: "5px", bottom: "-16px", fontSize: "12px", lineHeight: "1.1" }}>
                        {`${watch().Description?.[index]?.name?.length}/90`}
                      </div>
                      {errors.Description?.[index]?.name &&
                        <ErrorText
                          variant="caption"
                          className="no-space"
                        >
                          {errors.Description?.[index]?.name?.message}
                        </ErrorText>
                      }
                      {index !== 0 &&
                        <ButtonRemove onClick={() => {
                          headRemove(index)
                          descriptionRemove(index)
                        }} ><CrossIcon /></ButtonRemove>
                      }
                    </div>
                  ))}
                  {watch('Description')?.length < 15 && (
                    <ButtonAdd
                      disabled={watch('Description')?.slice(-1)[0]?.name === '' || watch('Headline')?.slice(-1)[0]?.name === ''}
                      onClick={() => {
                        headAppend('')
                        descriptionAppend('')
                      }}>Add Headline and Description</ButtonAdd>
                  )}

                </FormGroup>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                What are your goals for this campaign?
              </WidgetHeading>
              <WidgetBody>
                <FormRow>
                  <RowHeading
                    variant="h2"
                    id="default-goals-group-label"
                  >
                    Default Goals
                  </RowHeading>
                  <IconsWrapper>
                    <IconItem>
                      <ClicksIcon />
                      Clicks
                    </IconItem>
                    <IconItem>
                      <ImpressionsIcon />
                      Impressions
                    </IconItem>
                  </IconsWrapper>
                  {/* <FormControl>
                    <IconGroupsWrapper>
                      <IconControlsGroup
                        name="default-goals"
                        aria-labelledby="default-goals-group-label"
                        defaultValue="clicks"
                      >
                        <IconRadioLabel control={<Checkbox {...register('goals.clicks')} />}
                          label={
                            <span>
                              <i><ClicksIcon /></i>
                              Clicks
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox {...register('goals.impressions')} />}
                          label={
                            <span>
                              <i><ImpressionsIcon /></i>
                              Impressions
                            </span>
                          }
                        />
                      </IconControlsGroup>
                    </IconGroupsWrapper>
                  </FormControl> */}
                </FormRow>
                <CustomDivider />
                <FormRow>
                  <RowHeading
                    variant="h2"
                    id="additional-goals-group-label"
                  >
                    Additional Goals
                  </RowHeading>
                  <FormControl>
                    <IconGroupsWrapper className="iii-cols">
                      <IconControlsGroup
                        aria-labelledby="additional-goals-group-label"
                        defaultValue="download-presentation"
                      >
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.meeting_requests')} {...register('goals.meeting_requests')} />}
                          label={
                            <span>
                              <i><MeetRequestIcon /></i>
                              Meeting Requests
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.download_presentation')} {...register('goals.download_presentation')} />}
                          label={
                            <span>
                              <i><DownloadPresentationIcon /></i>
                              Download Presentation
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.download_factsheet')} {...register('goals.download_factsheet')} />}
                          label={
                            <span>
                              <i><DownloadPresentationIcon /></i>
                              Download Factsheet
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.3_pageviews')} {...register('goals.3_pageviews')} />}
                          label={
                            <span>
                              <i><IIIPageViewsIcon /></i>
                              3+ Pageviews
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.3_minutes_on_site')} {...register('goals.3_minutes_on_site')} />}
                          label={
                            <span>
                              <i><IIIPageViewsIcon /></i>
                              3+ Minutes on Site
                            </span>
                          }
                        />
                        <IconRadioLabel control={<Checkbox checked={getValues('goals.other_goals')} {...register('goals.other_goals')} />}
                          label={
                            <span>
                              <i><OtherGoalsIcon /></i>
                              Other Goals
                            </span>
                          }
                        />
                      </IconControlsGroup>
                    </IconGroupsWrapper>
                  </FormControl>
                </FormRow>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                What should people be searching for when they see your campaign?
              </WidgetHeading>
              <WidgetBody>
                <FormGroup
                  className={`${errors.positivePhraseMatchKeywords && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Positive Phrase Match Keywords
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>

                  <TagAutocomplete control={control}
                    name="positivePhraseMatchKeywords"
                  />

                  {errors.positivePhraseMatchKeywords &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.positivePhraseMatchKeywords.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={`${errors.positiveExactMatchKeywords && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Positive Exact Match Keywords
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <TagAutocomplete control={control}
                    name="positiveExactMatchKeywords"
                  />
                  {errors.positiveExactMatchKeywords &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.positiveExactMatchKeywords.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={`${errors.negativePhraseMatchKeywords && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Negative Phrase Match Keywords
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <TagAutocomplete
                    control={control}
                    name="negativePhraseMatchKeywords"
                  />
                  {errors.negativePhraseMatchKeywords &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.negativePhraseMatchKeywords.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={`${errors.negativeExactMatchKeywords && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Negative Exact Match Keywords
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <TagAutocomplete
                    control={control}
                    name="negativeExactMatchKeywords"
                  />

                  {errors.negativeExactMatchKeywords &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.negativeExactMatchKeywords.message}
                    </ErrorText>
                  }
                </FormGroup>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                Who should see your campaign?
              </WidgetHeading>
              <WidgetBody>
                <FormGroup
                  className={`${errors.locations && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Include location
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>

                  <Controller
                    control={control}
                    name="locations"
                    defaultValue={[]}
                    render={({ field: { onChange, value } }) => (
                      <LocationTag name="locations" exclude="excludedLocations" onChange={onChange} value={value} watch={watch} />
                    )} />
                  {errors.locations &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.locations.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={`${errors.excludedLocations && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Exclude Location
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <Controller
                    control={control}
                    defaultValue={[]}
                    name="excludedLocations"
                    render={({ field: { onChange, value } }) => (
                      <LocationTag name="excludedLocations" exclude="locations" onChange={onChange} value={value} watch={watch} />
                    )} />
                  {errors.excludedLocations &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.excludedLocations.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className={`${errors.languages && 'form--error'} custom-field-group`}
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Languages
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <FormSelectField>
                    <Controller
                      control={control}
                      name="languages"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          multiple
                          value={value ? value : []}
                          onChange={onChange}
                          MenuProps={MenuProps}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value: any) => (
                                <Chip key={value} label={value} onDelete={handleDelete} deleteIcon={<ChipDeleteIcon />} />
                              ))}
                            </Box>
                          )}
                        >
                          {languages.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      )} />
                  </FormSelectField>
                  {errors.languages &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.languages.message}
                    </ErrorText>
                  }
                </FormGroup>
                <FormGroup
                  className="custom-field-group"
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Age
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <Controller
                    control={control}
                    name="age"
                    render={({ field: { onChange, value } }) => (
                      <RangeSlider
                        getAriaLabel={() => 'Temperature range'}
                        value={value ? value : [18, 65]}
                        onChange={onChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                      />)}
                  />
                </FormGroup>
                <FormGroup>
                  <LabelHeading
                    variant="h3"
                  >
                    Gender
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <FormControl>
                    <ChecksGroupsWrapper>
                      <BoxControlsGroup
                        name="gender"
                        aria-labelledby="gender-group-label"
                        defaultValue="men"
                      >
                        <BoxRadioLabel control={<Checkbox checked={getValues('gender.men')} {...register('gender.men')} />}
                          label="Men"
                        />
                        <BoxRadioLabel control={<Checkbox checked={getValues('gender.women')} {...register('gender.women')} />}
                          label="Women"
                        />
                      </BoxControlsGroup>
                    </ChecksGroupsWrapper>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <LabelHeading
                    variant="h3"
                  >
                    Device Type
                    <strong className="ldf-info-wrap"><span className="liw-info-tip"><span>something to write for info</span></span><img src={infoIcon} alt="info" /></strong>
                  </LabelHeading>
                  <FormControl>
                    <ChecksGroupsWrapper>
                      <BoxControlsGroup
                        name="device-type"
                        aria-labelledby="device-type-label"
                        defaultValue="Mobile"
                      >
                        <BoxRadioLabel control={<Checkbox checked={getValues('devicetype.desktop')} {...register('devicetype.desktop')} />}
                          label="Desktop"
                        />
                        <BoxRadioLabel control={<Checkbox checked={getValues('devicetype.tablet')} {...register('devicetype.tablet')} />}
                          label="Tablet"
                        />
                        <BoxRadioLabel control={<Checkbox checked={getValues('devicetype.mobile')} {...register('devicetype.mobile')} />}
                          label="Mobile"
                        />
                      </BoxControlsGroup>
                    </ChecksGroupsWrapper>
                  </FormControl>
                </FormGroup>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                When should your campaign run?
              </WidgetHeading>
              <WidgetBody>
                <FieldsColumnsWrapper>
                  <FieldCol>
                    <FormGroup>
                      <LabelHeading
                        variant="h3"
                      >
                        Start Date
                      </LabelHeading>
                      <DatePickerWrapper dateAdapter={AdapterDayjs}><Controller
                        control={control}
                        name="campaignStartDate"
                        render={({ field: { onChange, value } }) => (
                          <CustomDatepicker value={value ? dayjs(value) : dayjs()} onChange={onChange} />
                        )} />
                      </DatePickerWrapper>
                    </FormGroup>
                  </FieldCol>
                  <FieldCol>
                    <FormGroup>
                      <LabelHeading
                        variant="h3"
                      >
                        End Date
                      </LabelHeading>
                      <DatePickerWrapper dateAdapter={AdapterDayjs}><Controller
                        control={control}
                        name="campaignEndDate"
                        render={({ field: { onChange, value } }) => (
                          <CustomDatepicker value={value ? dayjs(value) : dayjs()} onChange={onChange} />
                        )} />
                      </DatePickerWrapper>
                    </FormGroup>
                  </FieldCol>
                </FieldsColumnsWrapper>
              </WidgetBody>
            </WidgetBox>
            <WidgetBox>
              <WidgetHeading
                variant="h2"
              >
                How would you like to fund your campaign?
              </WidgetHeading>
              <WidgetBody>
                <FundsDetailWrapper>
                  <TextLine
                    variant="caption"
                  >
                    Your Wallet
                  </TextLine>
                  <TextPrice
                    variant="caption"
                  >
                    ${balance.toFixed(2)}
                  </TextPrice>
                  <ButtonLink type="button" onClick={handleOpen}>
                    <PlusIcon />
                    Add Funds
                  </ButtonLink>
                </FundsDetailWrapper>
                <CustomDivider />
                <FormGroup
                  className={
                    errors.budget && 'form--error'
                  }
                >
                  <LabelHeading
                    variant="h3"
                  >
                    Campaign Budget
                  </LabelHeading>
                  <FormField id="ldf-budget" type="number" {...register('budget', {
                    validate: value => 
                    parseFloat(value) <= 0 && parseFloat(balance.toFixed(2)) > 0 ? "This campaign Budget should be greater then 0" :
                    parseFloat(value) <= 0 && parseFloat(balance.toFixed(2)) === 0 && 'There are insufficient funds in your wallet. Please add funds and then launch the campaign.' ||
                    parseFloat(value) <= parseFloat(balance.toFixed(2))  
                    || 'There are insufficient funds in your wallet. Please add funds and then launch the campaign.'  // JS only: <p>error message</p> TS only support string
                  })} hiddenLabel />
                  {errors.budget &&
                    <ErrorText
                      variant="caption"
                    >
                      {errors.budget.message}
                    </ErrorText>
                  }
                </FormGroup>
                <CustomDivider />
                <StatsTable>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">Your Wallet</TableCell>
                      <TableCell align="right">{usdDollar(balance)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Campaign Budget</TableCell>
                      <TableCell align="right">{usdDollar(campaignBudget)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Your Wallet After Purchase</TableCell>
                      <TableCell align="right">{usdDollar(balance - campaignBudget)}</TableCell>
                    </TableRow>
                  </TableBody>
                </StatsTable>
                <CustomDivider />
                <ButtonWrapper className="btn-wrapper">
                  <Button onClick={() => onSave(watch())} disabled={saving} className="btn-draft">{saving ? 'Saving' : 'Save as Draft'}</Button>
                  <ButtonPrimary type="submit" disabled={saving || parseFloat(campaignBudget) > parseFloat(balance.toFixed(2)) || balance < 1}>Launch campaign <ArrowRightIcon /></ButtonPrimary>
                </ButtonWrapper>
              </WidgetBody>
            </WidgetBox>
          </FormCol>
          <DesignPreview data={watch} />
          <FundsModal open={open} onClose={handleClose}>
            <ModalContent>
              <StripeForm onClose={() => handleClose()} balance={balance} />
            </ModalContent>
          </FundsModal>
        </form>
      </Wrapper>
    </FormWrapper >
  )
}
export default MetaForm;