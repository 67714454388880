import { styled, Typography, Box, Button, DialogContent, Dialog } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const WidgetMaxWrapper = styled(Box)`
  max-width: 421px;
  margin-bottom: 24px;
`;

export const PageHeading = styled(Typography)`
  font: 900 24px/1.12 "Playfair Display", "Times New Roman", Times, serif;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 8px;
  border-bottom: 4px solid #E3C050;
  color: #E9E9E9;
`;

export const StatsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const HeadRowWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;

  @media (min-width: 768px){
    flex-direction: row;
  }
`;

export const ExtraWrapper = styled(Box)`
  max-width: 1060px;
`;

export const HeadingCol = styled(Box)`
  flex-grow: 1;
`;

export const FundsDetailWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  flex-shrink: 0;
  max-width: 40%;
`;

export const TextLine = styled(Typography)`
  color: #8C8E95;
  font-size: 12px;
`;

export const TextPrice = styled(Typography)`
  color: #E9E9E9;
  background-color: #2B2B2B;
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
`;

export const ButtonLink = styled(Button)`
  min-width: 1px;
  color: #8C8E95;
  padding: 0;
  font-size: 14px;
  text-transform: none;

  &:hover{
    background-color: transparent;
    color: #fff;
  }

  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
`;

export const FundsModal = styled(Dialog)`
  box-shadow: none;

  .MuiPaper-elevation{
    border: 1px solid transparent;
    border-radius: 11px;
    background: #1f1f1f;
    width: 100%;

    @media (min-width: 576px){
      width: 461px;
    }
  }

  .MuiModal-backdrop{
    background-color: rgba(19, 19, 19, 0.9);
  }

  table{
    margin-top: 24px;

    td{
      font-weight: 400;
      font-size: 14px;

      @media (min-width: 576px){
        font-size: 16px;
      }
    }

    tbody{

      tr{

        &:last-child{

          td{
            padding-bottom: 24px;
          }
        }
      }
    }

    tfoot{

      tr{

        &:first-child{

          td{
            padding-top: 24px;
          }
        }
      }
    }
  }
`;

export const ModalContent = styled(DialogContent)`
  padding: 22px;
  
  @media (min-width: 576px){
    padding: 36px;
  }
`;

export const ModalHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const FormGroup = styled(Box)`
  
  + div{
    margin-top: 24px;
  }

  &.form--error{

    > .MuiTextField-root{
      border-color: #BB4A4A;
    }
  }
`;

export const EmptyContainer = styled(Box)`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 400px);
`;

export const AlignWrapper = styled(Box)`
  width: 100%;
`;

export const AlignMiddle = styled(Box)`
  margin: auto;
  max-width: 610px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const DescriptionText = styled(Typography)`
  font-size: 16px;
  line-height 1.5;
  color: #C0C0C0;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 24px;
  min-width: 108px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;
  max-width: none;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`;