import ShadeBox from "components/ShadeBox";
import { Wrapper, Title, Count } from "./style";

const StatisticColumn = ({title, count}: any) => (
  <ShadeBox>
    <Wrapper>
      {title &&
        <Title
          variant="h3"
        >
          {title}
        </Title>
      }
      {count &&
        <Count
          variant="h4"
        >
          {count}
        </Count>
      }
    </Wrapper>
  </ShadeBox>
);

export default StatisticColumn;