import { Autocomplete, Chip, TextField } from "@mui/material";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { Controller } from "react-hook-form";


export const TagAutocomplete = ({ control, name, rules }: any) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id="tags"
          options={[]}
          freeSolo

          renderTags={(value, getTagProps) =>
            value.map((tag, index) => (
              <Chip deleteIcon={<ChipDeleteIcon />} label={tag} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} multiline />
          )}
          onChange={(e, value: any) => {
            const data = value.map((v: any) => v.split('\n')).flat().filter((tag: any) => tag !== '' && tag != '\n');
            field.onChange(data)
          }}
        />
      )}
    />
  );
};