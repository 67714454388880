/* global google */
import React, { useState, useEffect, useContext } from "react";
import { Wrapper, NavWrapper, ButtonNav, Separator, Logo, ActionsWrapper, LoginButton, ProfileModal, ModalContent, PictureWrapper, EditPicButton, ButtonPrimary, ButtonWrapper, TextAreaField, FormField, LabelHeading, FormGroup, CustomSelect } from './style';
import MenuItem from '@mui/material/MenuItem';
import { MenuIcon } from 'components/Icons/MenuIcon';
import logo from "../../assets/images/6ix-Leads-logo.svg";
import avatar from "../../assets/images/avatar.png";
import userLoginAvatar from "../../assets/images/userLogin.png";
import { useNavigate } from "react-router-dom";
import { signInWithCredentialResponse, auth, userLogout } from "../../firebase";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserIcon } from "components/Icons/UserIcon";
import Dropdown from "react-bootstrap/Dropdown";
import AppsDropdown from "components/AppsDropdown";
import SelectorDropdown from "components/SelectorDropdown";
import ProfileDropdown from "components/ProfileDropdown";
import { CrossIcon } from "components/Icons/CrossIcon";
import TextField from '@mui/material/TextField';
import { PencilIcon } from "components/Icons/PencilIcon";
import { ToastContainer } from "react-toastify";
import LanguageTranslate from "components/LanguageTranslate/LanguageTranslate";
import { BurgerMenuIcon } from "components/Icons/BurgerMenuIcon";
import AddIcon from "@mui/icons-material/Add";
import {
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { SESSION_AUTH } from "utils/auth";
import { AppContext } from "context";

const HeaderNew = () => {
  const navigate = useNavigate();
  const { isUserSession, UPDATE_USER_TOKEN } = SESSION_AUTH();
  const [user, loading] = useAuthState(auth);
  const { setUniversalData } = useContext(AppContext)
  const [userInfo, setUserInfo] = useState("");
  const [mobSidebar, setMobSidebar] = useState(true);
  const [loginRequest, setLoginRequest] = useState(false);

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: true,
      callback: loginWithWithCredential,
    });

    google.accounts.id.prompt();
  };

  const loginWithWithCredential = async (response) => {
    const data = await signInWithCredentialResponse(response);
    if (data) {
      setLoginRequest(false);
      navigate(0);
    }
  };

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (isUserSession) {
      setUniversalData(isUserSession)
      setUserInfo(isUserSession);
    } else {
      setUserInfo('');
      initializeGSI();
    }
  }, [isUserSession]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <NavWrapper>
        <ButtonNav
          className={
            mobSidebar
              ? ""
              : "sidebar-opener--close"
          }
          onClick={() => setMobSidebar(!mobSidebar)}
        >
          <i className="icn-wrap">
            <BurgerMenuIcon />
          </i>
          <i className="icn-close">
            <CrossIcon />
          </i>
        </ButtonNav>
        <Separator />
        <Logo href="/">
          <img src={logo} alt="6ix Leads" width={'76'} height={'42'} />
        </Logo>
      </NavWrapper>
      <ActionsWrapper>
        {userInfo &&
          <>
            <SelectorDropdown label={userInfo.email} id="six" />
            <Separator />
          </>
        }
        <LanguageTranslate />
        <AppsDropdown label="APPS" id="apps" />
        {userInfo ? (
          <ProfileDropdown user={userInfo} />
        ) : !loading ? (
          <LoginButton
            onClick={handleLogin}
          >
            <i className="icn-wrap">
              <UserIcon />
            </i>
            LOGIN
          </LoginButton>
        ) : null
        }
      </ActionsWrapper>
      <div className={mobSidebar ? "sixads-users" : "sixads-users open"}>
        {/* new_ads button */}
        <div className="sixads-users-newads">
          <div className="sixads-users-newads_wrapper">
            <div className="sixads_btn-wrapper">
              <button
                className="sixads-users-newads-btn"
                onClick={() => {
                  setMobSidebar(!mobSidebar);
                  navigate("/lead");
                }}
              >
                <AddIcon /> New Ad Campaign
              </button>
            </div>
            <nav aria-label="sidebar navigation">
              <List className="side-nav-list" disablePadding>
                {userInfo &&
                  <>
                    <ListItemButton
                      component="a"
                      href="/campaign-dashboard"
                      className="sbwListButton"
                    >
                      <ListItemText
                        primary={"Campaign Dashboard"}
                      />
                    </ListItemButton>
                    <ListItemButton
                      component="a"
                      href="/drafts"
                      className="sbwListButton"
                    >
                      <ListItemText
                        primary={"Drafts"}
                      />
                    </ListItemButton>
                  </>
                }
              </List>
            </nav>
          </div>
        </div>
      </div>
      {/* sidebar_end */}
      <div
        className={mobSidebar ? "d-none" : "sixads-overlay"}
        onClick={() => setMobSidebar(true)}
      ></div>
      {!userInfo && loginRequest ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme={"dark"}
        position="bottom-left"
      />
      <ProfileModal open={open} onClose={handleClose}>
        <ModalContent>
          <form>
            {/* <PictureWrapper>
              <img src={userInfo.profileImage || avatar} alt="" />
            </PictureWrapper> */}
            <EditPicButton>
              <PencilIcon />
              Edit photo
            </EditPicButton>
            <FormGroup>
              <LabelHeading variant="caption">Email</LabelHeading>
              <FormField name="email" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Name</LabelHeading>
              <FormField name="name" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Phone Number</LabelHeading>
              <FormField name="phone_num" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Job title</LabelHeading>
              <FormField name="job_title" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Company</LabelHeading>
              <FormField name="company" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Investor Type</LabelHeading>
              <CustomSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select"
              >
                <MenuItem>Ten</MenuItem>
                <MenuItem>Twenty</MenuItem>
                <MenuItem>Thirty</MenuItem>
              </CustomSelect>
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Bio (Optional)</LabelHeading>
              <TextAreaField name="bio" hiddenLabel />
            </FormGroup>
            <FormGroup>
              <LabelHeading variant="caption">Company Website (Optional)</LabelHeading>
              <FormField name="company_website" hiddenLabel />
            </FormGroup>
            <ButtonWrapper>
              <ButtonPrimary
                type="submit"
              >
                Save
              </ButtonPrimary>
            </ButtonWrapper>
          </form>
        </ModalContent>
      </ProfileModal>
    </Wrapper>
  );
};

export default HeaderNew;
