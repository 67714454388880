import { styled, Link } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  width: 100%;
  margin: 35px auto 0;
  max-width: 300px;

  @media (min-width: 768px){
    margin: 35px 0 0;
    max-width: none;
  }
`;

export const PrimaryLink = styled(Link)`
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding: 12px 20px;
  border: 1px solid #E3C050;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  background-color: #000;
  width: 100%;

  @media (min-width: 576px){
    width: auto;
  }
  @media (min-width: 992px){
    padding-left: 36px;
    padding-right: 36px;
  }

  &:hover{
    background-color: #131313;
    color: #fff;
  }
`;

export const SecondaryLink = styled(Link)`
  border-radius: 6px;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding: 12px 20px;
  border: 1px solid #454648;
  color: #c0c0c0;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  width: 100%;

  @media (min-width: 576px){
    width: auto;
  }
  @media (min-width: 992px){
    padding-left: 36px;
    padding-right: 36px;
  }

  &:hover{
    background-color: #2B2B2B;
    border-color: #E3C050;
    color: #fff;
  }
`;