import { Select, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BoxHeading, BoxWrapper, ButtonPrimary, CustomDivider, FootNote, FormCol, FormField, FormGroup, FormRow, Heading, HeadingWrapper, LabelHeading, PrifileIcon, SubTitle, TextPara, Title, TitleWrapper, UsersTableContainer, Wrapper, FormSelectField, TabsetList, TabButton } from "./style";
import { Controller, useForm } from "react-hook-form";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import SelectorDropdown from "components/SelectorDropdown";
import { AppContext } from "context";

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <UsersTableContainer>
          <Table aria-label="reports table">
            <TableHead>
              <TableRow>
                <TableCell>S.no</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>
                  <PrifileIcon
                  >
                    A
                  </PrifileIcon>
                </TableCell>
                <TableCell>
                  <Title
                    variant="caption"
                  >
                    adnan
                  </Title>
                  <SubTitle
                    variant="caption"
                  >
                    adnan@6ix.com
                  </SubTitle>
                </TableCell>
                <TableCell>Owner</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </UsersTableContainer>
      )}
    </div>
  );
}

export default function GeneralTabpanel({ }: any) {
  const [user] = useAuthState(auth);
  const { register, handleSubmit, watch, control, formState: { errors }, setValue } = useForm();
  const { org, updateOrgList, updateOrg }= useContext(AppContext);

  const onSubmit = async (data: any) => {
    baseApi.post('/api/update/orgname/', {
      ...data,
      orgName: org.id
    })
      .then((res) => {
        updateOrgList()
        updateOrg({
          orgName: data.NewOrgName,
          orgId: org.id
        })
        toast.success(res.data.message)
      })
      .catch((err) => toast.error(err.data.message))
  }

  useEffect(() => {
    setValue('NewOrgName', org.orgName)
  }, [org]);


  return (
    <Wrapper>
      <BoxWrapper>
        <BoxHeading
          className="mb"
          variant="h3"
        >
          Org Name
        </BoxHeading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <LabelHeading
              variant="h4"
            >
              This is your org’s visible name within 6ix. For example, the name of your company.
            </LabelHeading>
            <FormField id="ldf-Name" inputProps={{ maxLength: 30 }} {...register('NewOrgName', { required: "Org name is required" })} hiddenLabel />
          </FormGroup>
          <CustomDivider />
          <FootNote>
            <TextPara
              variant="body1"
            >
              Please use 30 characters at maximum.
            </TextPara>
            <ButtonPrimary type="submit">SAVE</ButtonPrimary>
          </FootNote>
        </form>
      </BoxWrapper>
    </Wrapper>
  );
}