import ContainerFluid from "components/ContainerFluid";
import { Section, PageHeading, HeadingWrapper, WidgetMaxWrapper, StatsWrapper, HeadRowWrapper, ExtraWrapper, ButtonLink, FundsDetailWrapper, TextLine, TextPrice, FundsModal, ModalContent, ModalHeading, HeadingCol, EmptyContainer, AlignWrapper, DescriptionText, AlignMiddle, ButtonPrimary } from "./style";
import StatisticColumn from "components/StatisticColumn";
import { PlusIcon } from "components/Icons/PlusIcon";
import React, { useContext, useEffect, useState } from "react";
import StatisticsDashboardTable from "components/StatisticsDashboardTable";
import baseApi from "api/baseApi";
import { useNavigate } from "react-router-dom";
import { StripeForm } from "components/StripeForm";
import { AppContext } from "context";
import { usdDollar } from "utils/helper";
import { ButtonArrowIcon } from "components/Icons/ButtonArrowIcon";
import { map } from "lodash";

export default function CampaignDashboardSection({ pTop, pBottom }: any) {
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const { balance, org } = useContext(AppContext)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (org) {
      setData([])
      baseApi.get(`/api/campaign/${org.id}`).then((res) => setData(res.data))
    }
  }, [org])

  const item = data && data[0]?._airbyte_data;

  const getTotal = (data: any, campaignItem: any) => {
    let itemValue: any = null;
    
    map(data, item => {
      itemValue += parseFloat(item?._airbyte_data?.[campaignItem])
    });
    return itemValue;
  }

  return (
    <Section
      className="CampaignDashboardSection"
      sx={{
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }}
    >
      <ContainerFluid>
        <HeadingWrapper>
          <HeadingCol>
            <PageHeading
              variant="h1"
            >
              {org?.orgName + " Campaigns"}
            </PageHeading>
          </HeadingCol>
          <FundsDetailWrapper>
            <TextLine
              variant="caption"
            >
              Your Wallet
            </TextLine>
            <TextPrice
              variant="caption"
            >
              {usdDollar(balance)}
            </TextPrice>
            <ButtonLink type="button" onClick={handleOpen}>
              <PlusIcon />
              Add Funds
            </ButtonLink>
            <FundsModal open={open} onClose={handleClose}>
              <ModalContent>
                <StripeForm onClose={() => handleClose()} />
              </ModalContent>
            </FundsModal>
          </FundsDetailWrapper>
        </HeadingWrapper>
        {/* <WidgetMaxWrapper>
          <HeadlineWidget heading="Your headline will show up here" url="#" urlLabel="Your URL will show up here" description="Your description will show up here" />
        </WidgetMaxWrapper> */}
        <HeadRowWrapper>
          <StatsWrapper>
            <StatisticColumn title="Total Campaigns" count={data?.length || 0} />
            <StatisticColumn title="Total Budget" count={usdDollar(getTotal(data, 'totalbudget') || 0)} />
            <StatisticColumn title="Total Spent" count={usdDollar(getTotal(data, 'cost') || 0)} />
            <StatisticColumn title="Budget Remaining" count={usdDollar(getTotal(data, 'totalbudget') - getTotal(data, 'cost') || 0)} />
            <StatisticColumn title="Total Impressions" count={getTotal(data, 'impressions') || 0} />
            <StatisticColumn title="Total Clicks" count={getTotal(data, 'clicks') || 0} />
          </StatsWrapper>
          {/* <StatsExportDropdown id="stats-export" label="EXPORT" /> */}
        </HeadRowWrapper>
        <StatisticsDashboardTable data={data} />
        {data?.length === 0 &&
          <EmptyContainer>
            <AlignMiddle>
              <AlignWrapper>
                <DescriptionText
                  variant="body1"
                >
                  This is where the data will be displayed <br />after the ads campaign has been launched
                </DescriptionText>
                <ButtonPrimary type="button" onClick={() => navigate('/lead')}>Launch campaign <ButtonArrowIcon /></ButtonPrimary>
              </AlignWrapper>
            </AlignMiddle>
          </EmptyContainer>
        }
      </ContainerFluid>
    </Section>
  );
}