import { styled, Box, Typography, TextField, Divider, RadioGroup, FormControlLabel, FormControl, Button, Table, Dialog, DialogContent } from '@mui/material';
import Slider from '@mui/material/Slider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TagAutocomplete } from "./TagAutocomplete";
import { LocationTag } from "./LocationTag";

export const FormWrapper = styled('section')`
  width: 100%;
  overflow: hidden;
  background-color: #131313;
  padding: 105px 10px;

  .slick-prev {
    left: -18px;
  }

  .slick-next {
    right: -18px;
  }

  .MuiAutocomplete-root{
    background-color: #131313;

    &,
    .MuiInputBase-root,
    .MuiButtonBase-root{
      color: #c0c0c0;
    }

    .MuiInputLabel-root{
      color: #8C8E95;
    }

    &.Mui-focused{

      fieldset{
        border-color: #E3C050 !important;
        border-width: 1px;
      }
    }

    fieldset{
      border-color: #373737 !important;
    }

    textarea.MuiInputBase-inputMultiline{
      min-width: 150px;
    }

    .MuiChip-filled{
      background-color: #373737;
      color: #E9E9E9;
      font-size: 14px;
      line-height: 1;
      border-radius: 4px;

      .MuiSvgIcon-root{
        width: 12px;
        height: 12px;
        color: #8C8E95;
      }
    }
  }
`;

export const LociFieldWrapper = styled(`div`)`
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 46px;
  padding: 8px;

  input{
    color: #e9e9e9;
    box-sizing: border-box;
    display: block;
    min-width: 130px;
    font-size: 16px;
    line-height: 18px;
    padding: 0 4px;
    background-color: transparent;
    border: 0;
    min-height: 28px;

    &:focus{
      outline: none;
    }
  }

  .rga-tags-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &:empty{
      display: none;
    }

    &:has(.rga-tag) + input{
      padding: 6px 8px;
    }
  }

  .rga-tag{
    display: inline-flex;
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;
    padding: 9px 27px 9px 12px;
    position: relative;
    font-weight: 400;

    .MuiButton-root{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: #e9e9e9;
      min-width: 1px;

      &:hover{
        color: #fff;
        background-color: transparent;
      }
    }

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
    }
  }
`;

export const DatePickerWrapper = styled(LocalizationProvider)`
  
`;

export const StatsTable = styled(Table)`

  tbody{

    tr{

      &:first-child{

        td{
          padding-top: 0;
        }
      }
    }
  }
  
  td{
    border: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #E9E9E9;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    
    &:first-child{
      padding-left: 0;
    }
    
    &:last-child{
      padding-right: 0;
    }
  }

  tfoot{

    td{
      border-top: 1px solid #454648;
    }
  }
`;

export const FundsDetailWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export const TextLine = styled(Typography)`
  color: #8C8E95;
  font-size: 12px;
`;

export const TextPrice = styled(Typography)`
  color: #E9E9E9;
  background-color: #2B2B2B;
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
`;

export const ButtonLink = styled(Button)`
  min-width: 1px;
  color: #8C8E95;
  padding: 0;
  font-size: 14px;
  text-transform: none;

  &:hover{
    background-color: transparent;
    color: #fff;
  }

  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
`;

export const ButtonWrapper = styled(Box)`
  text-align: center;

  &.btn-wrapper {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .btn-draft {
    display: inline-flex;
    margin: 0 15px;
    cursor: pointer;
    color: #E3C050;

    &:hover {
      color: #E9E9E9;
    }
  }
`;

export const ModalFoot = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  @media (min-width: 576px){
    flex-direction: row;
  }

  button{

    @media (min-width: 576px){
      flex-grow: 1;
      flex-basis: 0;
    }
  }
`;

export const ButtonPrimary = styled(Button)`
  min-width: 108px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  &:disabled {
    opacity: 0.75;
    color: #E9E9E9;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`;

export const ButtonDefault = styled(Button)`
  min-width: 108px;
  background-color: transparent;
  color: #C0C0C0;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #373737;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }
`;

export const CustomDatepicker = styled(DatePicker)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 14px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .MuiIconButton-edgeEnd{
    color: #8C8E95;
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;

  form{
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`;

export const RangeSlider = styled(Slider)`
  color: #373737;
  height: 2px;
  margin-top: 24px;

  .MuiSlider-rail{
    opacity: 1;
  }

  .MuiSlider-thumb,
  .MuiSlider-track{
    background-color: #E3C050;
    color: #E3C050;

    &:focus-visible,
    &:before,
    &:hover,
    &:focus{
      box-shadow: none;
    }
  }

  .MuiSlider-valueLabel{
    transform: translateY(-100%) scale(1);
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    color: #E9E9E9;
    top: -4px;
  }
`;

export const FormCol = styled(Box)`
  width: 100%;

  @media (min-width: 992px){
    width: calc(33% - 12px);
  }
  
  &:nth-child(1){

    @media (min-width: 992px){
      width: calc(67% - 12px);
    }
  }
`;

export const FieldsColumnsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const FieldCol = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;

  @media (min-width: 576px){
    min-width: calc(50% - 12px);
  }
`;

export const CustomDivider = styled(Divider)`
  border-color: #2B2B2B;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const HiddenSeparator = styled(Divider)`
  border-color: transparent;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const IconControlsGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @media (min-width: 768px){
    gap: 36px;
  }

  .iii-cols &{

    @media (min-width: 768px){
      gap: 18px;
    }
  }
`;

export const BoxControlsGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ChecksGroupsWrapper = styled(Box)`
`;

export const IconRadioLabel = styled(FormControlLabel)`
  gap: 12px;
  color: #E9E9E9;
  margin: 0;
  padding: 8px 16px 8px 44px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 1;

  .MuiFormControlLabel-label{
    font-size: 14px;

    &:before{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: -1;
    }
  }

  .MuiRadio-root,
  .MuiCheckbox-root{
    padding: 0;
    position: static;

    &.Mui-checked{

      + .MuiFormControlLabel-label{

        &:before{
          background-color: #454648;
        }
      }
    }


    .MuiSvgIcon-root{
      display: none;
    }
  }
  
  .MuiSvgIcon-root{
    width: 30px;
    height: 30px;
  }

  i{
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 4px;
  }

  .iii-cols &{

    @media (min-width: 576px){
      width: calc(33.333% - 12px);
    }
  }
`;

export const BoxRadioLabel = styled(FormControlLabel)`
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #373737;
  color: #E9E9E9;
  margin: 0;
  position: relative;
  z-index: 1;

  .MuiFormControlLabel-label{
    font-size: 14px;

    &:before{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #2b2b2b;
      content: '';
      z-index: -1;
      border-radius: 6px;
      opacity: 0;
      pointer-events: none;
    }
  }
  
  .MuiRadio-root,
  .MuiCheckbox-root{
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #8C8E95;

    &:before{
      position: absolute;
      left: 3px;
      top: 5px;
      color: #E3C050;
      border: solid currentColor;
      border-width: 0 0 1px 1px;
      transform: rotate(-45deg);
      content: '';
      opacity: 0;
      width: 8px;
      height: 4px;
    }

    &.Mui-checked{
      border-color: #E3C050;

      &:before,
      + .MuiFormControlLabel-label:before{
        opacity: 1;
      }
    }

    .MuiSvgIcon-root{
      display: none;
    }
  }
`;

export const FormGroup = styled(Box)`

  &.form--error{

    > .MuiTextField-root{
      border-color: #BB4A4A;
    }
  }
  
  + div{
    margin-top: 24px;
  }

  .field-wrapper{
    position: relative;
    margin-bottom: 22px;

    + .field-wrapper{
      margin-top: 8px;
    }
  }

  .StripeElement {
    box-sizing: border-box;
    border: 1px solid #373737;
    border-radius: 6px;
    display: block;
    width: 100%;
    height: 46px;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 12px;
    color: #e9e9e9;
    background-color: #131313;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    position: relative;
    cursor: text;

    // &--focus{
    //   border-color: #e3c050;
    // }

    .__PrivateStripeElement,
    .__PrivateStripeElement iframe {
      height: 100%;
      width: 100%;
    }
  }

  &.custom-field-group{

    .MuiAutocomplete-inputRoot{
      padding-left: 9px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiAutocomplete-root{
      border-radius: 6px;
      background-color: #131313;
      border: 1px solid #373737;
    }

    .MuiFormControl-root{
      min-height: 46px;
      font-size: 16px;
      line-height: 18px;
      border-radius: 6px;
    }

    .MuiInput-input{
      padding: 7px 4px !important;
      color: #e9e9e9;
      font-size: 16px;
      line-height: 21px;
    }

    .MuiInput-root{

      &:after,
      &:before{
        display: none;
      }
    }

    .MuiAutocomplete-popupIndicator{
      color: #fff;
      right: 8px;
    }

    fieldset{
      border: 0 !important;
    }

    .MuiInputBase-adornedStart{
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

export const WidgetBox = styled(Box)`
  background-color: #1f1f1f;
  border-radius: 11px;
  border: 1px solid #2b2b2b;

  + div{
    margin-top: 24px;
  }
`;

export const WidgetHeading = styled(Typography)`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 24px;
  color: #e9e9e9;
  background-color: #2b2b2b;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`;

export const WidgetBody = styled(Box)`
  padding: 16px 24px;
`;

export const LabelHeading = styled(Typography)`
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  color: #8C8E95;
  margin-bottom: 6px;

  .ldf-info-wrap{
    position: relative;
    display: inline-block;
    vertical-align: -0.2em;
    margin-left: 6px;

    &:hover{

      .liw-info-tip{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .liw-info-tip{
    z-index: 2;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    padding-bottom: 6px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

    &:after{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #343434 transparent transparent transparent;
    }

    > span{
      background: #343434;
      box-shadow: 4px 4px 10px rgba(27, 27, 27, 0.25);
      border-radius: 8px;
      display: block;
      padding: 5px;
      width: 150px;
    }
  }
`;

export const FormRow = styled(Box)`
  
`;

export const IconGroupsWrapper = styled(Box)`
  
`;

export const RowHeading = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  color: #E9E9E9;
  margin-bottom: 16px;
`;

export const FormBody = styled(Box)`
  width: 100%;

  @media (min-width: 768px){
    display: flex;
    gap: 24px;
  }
`;

export const ContentWrapper = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 24px;

  @media (min-width: 768px){
    margin-bottom: 0;
  }
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #2B2B2B;
  border-radius: 6px;
  background-color: #1F1F1F;
  position: relative;
  padding: 20px 22px;

  @media (min-width: 992px){
    padding: 40px 32px;
  }

  + .ldf-box{
    margin-top: 24px;
  }
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const FormSelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  min-height: 46px;
  font-size: 14px;
  line-height: 18px;

  .MuiInputBase-input{
    padding: 8px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
      top: 10px;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }

  .MuiSelect-select{

    > .MuiBox-root{
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

export const HeadingHead = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #2b2b2b;
`;

export const LogoWrap = styled(Box)`
  
  img{
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }
`;

export const LineSeparator = styled(Divider)`
  border-color: #373737;
  height: 24px;
`;

export const FormHeading = styled(Typography)`
  font: 900 22px/1.167 'Playfair Display', 'Times New Roman', Times, serif;
  color: #fff;

  @media (min-width: 576px){
    font-size: 30px;
  }
`;

export const ButtonAdd = styled(Button)`
  padding: 6px 10px;
  color: #C0C0C0;
  font-size: 14px;
  background-color: #2B2B2B;
  border-radius: 6px;
  text-transform: none;
  margin-top: 12px;
  border: 1px solid transparent;

  &.Mui-disabled{
    color: #C0C0C0;
    opacity: 0.7;
  }

  &:hover{
    color: #fff;
    background-color: #000;
    border-color: #E3C050;
  }
`;

export const ButtonRemove = styled(Button)`
  color: #C0C0C0;
  font-size: 14px;
  background-color: transparent;
  border-radius: 6px;
  border: 0;
  min-width: 1px;
  position: absolute;
  right: 7px;
  top: 11px;
  padding: 5px;

  &:hover{
    color: #fff;
    background-color: transparent;
  }

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
  }
`;

export const FundsModal = styled(Dialog)`
  box-shadow: none;

  .MuiPaper-elevation{
    border: 1px solid transparent;
    border-radius: 11px;
    background: #1f1f1f;
    width: 100%;

    @media (min-width: 576px){
      width: 461px;
    }
  }

  .MuiModal-backdrop{
    background-color: rgba(19, 19, 19, 0.9);
  }

  table{
    margin-top: 24px;

    td{
      font-weight: 400;
      font-size: 14px;

      @media (min-width: 576px){
        font-size: 16px;
      }
    }

    tbody{

      tr{

        &:last-child{

          td{
            padding-bottom: 24px;
          }
        }
      }
    }

    tfoot{

      tr{

        &:first-child{

          td{
            padding-top: 24px;
          }
        }
      }
    }
  }
`;

export const ModalContent = styled(DialogContent)`
  padding: 22px;
  
  @media (min-width: 576px){
    padding: 36px;
  }
`;

export const ModalHeading = styled(Typography)`
  color: #E9E9E9;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const ErrorText = styled(Typography)`
  color: #BB4A4A;
  font-size: 13px;
  line-height: 17px;
  display: block;
  margin-top: 12px;

  &.no-space {
    margin-top: 0;
    position: absolute;
  }
`;

export const IconsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @media (min-width: 768px){
    gap: 36px;
  }
`;

export const IconItem = styled(Box)`
  gap: 12px;
  color: #E9E9E9;
  margin: 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  .MuiSvgIcon-root{
    width: 30px;
    height: 30px;
  }
`;