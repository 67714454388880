import Login from "pages/Login";
import { GetStartedGuide } from "pages/GetStartedGuide";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "pages/Home";
import MetaLead from "pages/MetaLead";

export const Unauthorized = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/get-started-guide" element={<GetStartedGuide />} />
    <Route path="/callback" element={<Login />} />
    <Route path="/lead" element={<MetaLead />} />
  </Routes>
);
