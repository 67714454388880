import { styled, Typography, Box } from '@mui/material';

export const Wrapper = styled(Box)`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  &:after{
    content: '';
    display: block;
    clear: both;
  }
`;