import HeadlineWidget from "components/HeadlineWidget"
import { HiddenSeparator } from "./style"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const DesignSlider = ({ Headline, DestinationURL, Description, preview = true }: any) => {
	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	if (!Headline && !Description) return null

	const count = Headline.length > Description.length ? Headline.length : Description.length

	return (
		<Slider {...settings}>
			{[...Array(count)].map((x: any, index: number) =>
				<div>
					<HiddenSeparator />
					<HeadlineWidget heading={
						Headline?.[index]?.name || "Your headline will show up here"} url="#"
						urlLabel={DestinationURL || "Your URL will show up here"}
						description={Description?.[index]?.name || preview && "Your description will show up here" || ' '} />
				</div>
			)}
		</Slider>
	)
}