import { PageWrapper } from "./style";
import CampaignReportsSection from "components/CampaignReportsSection";
const CampaignDrill = () => {
  return (
    <div className="sixads-content">
      <div className="sixads-adss">
        <PageWrapper>
          <CampaignReportsSection pTop="91px" pBottom="91px" />
        </PageWrapper>
      </div>
    </div>
  );
};
export default CampaignDrill;
