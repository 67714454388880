import dayjs from "dayjs";

export const dateHelper = (date: string) => {
    const inputDate = new Date(date);
    const options = { month: 'short', day: 'numeric', year: 'numeric' } as any;;
    const formattedDate = inputDate.toLocaleDateString('en-US', options);

    return formattedDate;
}

export const role = (data: string) => data.split('_').slice(1).join(' ')

export const tagDelete = (value: any[], index: number) => value.filter((v: any, i: any) => index !== i)

export const usdDollar = (data: any) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return USDollar.format(data)
}

export const leadBuilder = (data: any) => ({
    ...data,
    age: data?.age?.join('-') || "18-65",
    positivePhraseMatchKeywords: data?.positivePhraseMatchKeywords || [],
    positiveExactMatchKeywords: data?.positiveExactMatchKeywords || [],
    negativePhraseMatchKeywords: data?.negativePhraseMatchKeyvwords || [],
    negativeExactMatchKeywords: data?.negativeExactMatchKeywords || [],
    campaignStartDate: data?.campaignStartDate?.$d || dayjs(),
    campaignEndDate: data?.campaignEndDate?.$d || dayjs()
})