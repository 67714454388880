import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const IIIPageViewsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="30" rx="6" fill="#2B2B2B"/>
      <path fill="none" d="M20.334 9H9.66732C8.93094 9 8.33398 9.59695 8.33398 10.3333V17C8.33398 17.7364 8.93094 18.3333 9.66732 18.3333H20.334C21.0704 18.3333 21.6673 17.7364 21.6673 17V10.3333C21.6673 9.59695 21.0704 9 20.334 9Z" stroke="#E3C050" stroke-width="1.336" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M12.334 21H17.6673" stroke="#E3C050" stroke-width="1.336" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M15 18.3334V21" stroke="#E3C050" stroke-width="1.336" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};