import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const OtherGoalsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="30" rx="6" fill="#2B2B2B"/>
      <path d="M16.1332 15C16.1332 14.5582 15.775 14.2 15.3332 14.2C14.8914 14.2 14.5332 14.5582 14.5332 15C14.5332 15.4418 14.8914 15.8 15.3332 15.8C15.775 15.8 16.1332 15.4418 16.1332 15Z" stroke="#E3C050" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5326 15C10.5326 14.5582 10.1744 14.2 9.73262 14.2C9.29079 14.2 8.93262 14.5582 8.93262 15C8.93262 15.4418 9.29079 15.8 9.73262 15.8C10.1744 15.8 10.5326 15.4418 10.5326 15Z" stroke="#E3C050" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.7328 15C21.7328 14.5582 21.3746 14.2 20.9328 14.2C20.491 14.2 20.1328 14.5582 20.1328 15C20.1328 15.4418 20.491 15.8 20.9328 15.8C21.3746 15.8 21.7328 15.4418 21.7328 15Z" stroke="#E3C050" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};