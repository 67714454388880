import "react-responsive-carousel/lib/styles/carousel.min.css";
import HubspotForm from "react-hubspot-form";
import { Rings } from "react-loader-spinner";

const OrderPage = () => {
  return (
    <div className="sixads-content">
      <div className="sixads-adss">
        <div className="form-holder">
          <div id="summit-order">
            <div className="summit-order-inner">
              <div className="reqdemo-form-wrap">
                <HubspotForm
                  portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                  formId={process.env.REACT_APP_HUBSPOT_FORM_ID}
                  // onSubmit={() => console.log("Submit!")}
                  onReady={() => console.log("Form ready!")}
                  loading={
                    <Rings
                      height="80"
                      width="80"
                      color="#fff"
                      radius="6"
                      wrapperStyle={{}}
                      wrapperClass="sixPromoLoader"
                      visible={true}
                      ariaLabel="rings-loading"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderPage;
