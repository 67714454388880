import { Box, Typography } from "@mui/material";
import CourseDropdown from "components/CourseDropdown";
import { Wrapper, Section, BgWrapper, Heading } from "./style";
import SectionMainHeader from "components/SectionMainHeader";
import ButtonsFooter from "components/ButtonsFooter";
import BgImage from "../../assets/images/bg-img01.jpg"
import LandingVideo from "components/LandingVideo";

const IntroSection = ({pTop, pBottom, bgColor, refferOnTouch}: any) => (
  <Section
    className="IntroSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <CourseDropdown label="LEADS" id="intro-course" />
      <SectionMainHeader
        size="980px"
        description="Launch your next IR advertising campaign with 6ix Leads, the only investor advertising platform with investor-centric targeting, goal-oriented ad designs and measurable investor impact."
      >
        <Heading
          variant="h1"
        >
          Does your current IR program <strong>reach new investors?</strong>
        </Heading>
      </SectionMainHeader>
      <ButtonsFooter primaryLabel="GET IN TOUCH" refferOnTouch={refferOnTouch} secondaryLabel="LAUNCH A CAMPAIGN" secondaryLink="/lead" />
      <LandingVideo />
    </Wrapper>
    {/* <BgWrapper>
      <img src={BgImage} alt="" />
    </BgWrapper> */}
  </Section>
);

export default IntroSection;