import React, { useContext } from "react";
import { ContentArea, ContentColumns, ContentSidebar, Heading, IconText, Section, TabButton, TabsetList, UserHeading, Wrapper } from "./style";
import MemberTabpanel from "components/MemberTabpanel";
import { OfficeIcon } from "components/Icons/OfficeIcon";
import GeneralTabpanel from "components/GeneralTabpanel";
import { AppContext } from "context";

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
  const { children, value, index, ...other } = props;

  return (
    <>
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === 0 && (
        <GeneralTabpanel />
      )}
    </div>
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === 1 && (
        <MemberTabpanel />
      )}
    </div>
    </>
  );
}

export default function AccountSettings({ pTop, pBottom }: any) {
  const [value, setValue] = React.useState(0);
  const { org } = useContext(AppContext);

  const handleTabsChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <Section
      className="AccountSettings"
      sx={{
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }}
    >
      <Wrapper>
        <Heading
          variant="h1"
        >
          Org Settings
        </Heading>
        <ContentColumns>
          <ContentSidebar>
            <UserHeading
              variant="caption"
            >
              <IconText>
                <OfficeIcon />
              </IconText>
              {org?.orgName}
            </UserHeading>
            <TabsetList
              orientation="vertical"
              value={value}
              onChange={handleTabsChange}
            >
              <TabButton label="General" />
              <TabButton label="Members" />
            </TabsetList>
          </ContentSidebar>
          <ContentArea>
            <TabPanel value={value} index={0}>
              Item One
            </TabPanel>
          </ContentArea>
        </ContentColumns>
      </Wrapper>
    </Section>
  );
}