import { Button } from "@mui/material";
import { ChipDeleteIcon } from "components/Icons/ChipDeleteIcon";
import { useRef } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { LociFieldWrapper } from "./style";

export const LocationTag = ({ onChange, value, watch, name, exclude }: any) => {
	const ref = useRef<any>();


	const onChangeHandler = (place: any) => {
		if (watch(exclude).includes(place.formatted_address)) return


		onChange([...(watch(name) || []), place.formatted_address])
	}

	return (
		<LociFieldWrapper>
			<div className="rga-tags-wrapper">
				{value?.map((location: any, index: number) =>
					<strong className="rga-tag">{location}
						<Button
							onClick={() => onChange(value.filter((_: any, i: number) => i !== index))}
						>
							<ChipDeleteIcon />
						</Button>
					</strong>
				)}
			</div>
			<ReactGoogleAutocomplete
				ref={ref}
				style={{ width: "100%" }}
				options={{ types: [] }}
				onPlaceSelected={(place: any) => {
					onChangeHandler(place)
					ref.current.value = ''
				}}
			/>
		</LociFieldWrapper>
	)
}