import { styled, Typography, Box, Tabs, Tab } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 105px;
  padding-bottom: 45px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1252px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Heading = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 35px;
`;

export const ContentColumns = styled(Box)`

  @media (min-width: 768px){
    display: flex;
    gap: 20px;
    width: 100%;
  }
`;

export const ContentSidebar = styled(Box)`

  @media (min-width: 768px){
    width: 220px;
    flex-shrink: 0;
  }
`;

export const ContentArea = styled(Box)`
  width: 100%;
`;

export const UserHeading = styled(Typography)`
  font-size: 12px;
  color: #a1a1a1;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;
`;

export const IconText = styled(`span`)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid #575757;
  background-color: #373737;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .MuiSvgIcon-root{
    width: 20px;
    height: 20px;
  }
`;

export const TabsetList = styled(Tabs)`
  
  .MuiTabs-indicator{
    display: none;
  }
`;

export const TabButton = styled(Tab)`
  line-height: 20px;
  font-size: 14px;
  color: #575757;
  padding: 8px 12px;
  text-align: left;
  text-transform: none;
  min-width: 1px;
  display: block;
  width: 100%;
  min-height: 1px;
  max-width: none;

  &:hover,
  &.Mui-selected{
    color: #fff;
  }
`;