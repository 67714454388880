import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 24px;
`;

export const WidgetMaxWrapper = styled(Box)`
  max-width: 421px;
  margin-bottom: 24px;
`;

export const PageHeading = styled(Typography)`
  font: 900 24px/1.12 "Playfair Display", "Times New Roman", Times, serif;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 8px;
  border-bottom: 4px solid #E3C050;
  color: #E9E9E9;
`;

export const StatsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const HeadRowWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;

  @media (min-width: 768px){
    flex-direction: row;
  }
`;

export const ExtraWrapper = styled(Box)`
  max-width: 1060px;
`;