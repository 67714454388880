import { styled, Box, Typography, Divider, TextField, Button, TableContainer, FormControl, Tabs, Tab } from '@mui/material';

export const Wrapper = styled('div')`
  
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;
  color: #e9e9e9;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const TextPara = styled(Typography)`
  font-size: 14px;
  color: #8c8e95;
`;

export const BoxHeading = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #e9e9e9;

  &.mb{
    margin-bottom: 12px;
  }
`;

export const CustomDivider = styled(Divider)`
  margin-bottom: 24px;
  margin-top: 24px;
  border-color: #373737;
`;

export const HeadingWrapper = styled(Box)`
  margin-bottom: 20px;
`;

export const BoxWrapper = styled(Box)`
  border: 1px solid #2b2b2b;
  background-color: #1f1f1f;
  overflow: hidden;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 32px;
`;

export const FormRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const FormCol = styled(Box)`
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;

  @media (min-width: 640px){
    min-width: calc(50% - 10px);
  }
  @media (min-width: 768px){
    min-width: 100%;
  }
  @media (min-width: 992px){
    min-width: calc(50% - 10px);
  }
`;

export const FormGroup = styled(Box)`
  
`;

export const LabelHeading = styled(Typography)`
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
  color: #8c8e95;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 19px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 19px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const FootNote = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;

  button{
    margin-left: auto;
  }
`;

export const ButtonPrimary = styled(Button)`
  min-width: 1px;
  max-width: 130px;
  flex-shrink: 0;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 8px 20px;

  &:hover{
    background-color: #000;
    color: #E3C050;
    border-color: #E3C050;
  }
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PrifileIcon = styled(`span`)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #373737;
  border: 1px solid #575757;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #c0c0c0;
  font-size: 14px;
  font-weight: 600;
`;

export const Title = styled(Typography)`
  display: block;
`;

export const SubTitle = styled(Typography)`
  display: block;
`;

export const UsersTableContainer = styled(TableContainer)`
  background-color: #1f1f1f;
  overflow: hidden;
  border-radius: 4px;

  thead{

    th{
      border-bottom: 1px solid #373737;
    }
  }

  td, th{
    color: #fff;
    font-size: 14px;
    border: 0;
    padding: 16px;

    &:nth-child(1),
    &:nth-child(2){
      width: 62px;
    }

    &:nth-child(3){
      width: 55%;
    }
  }
`;

export const FormSelectField = styled(FormControl)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 19px;

  .MuiInputBase-input{
    padding: 10px 12px 6px;
    color: #e9e9e9;
    font-size: 16px;
    line-height: 19px;
  }

  input{

    + .MuiSvgIcon-root{
      color: #fff;
    }
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .MuiChip-filled{
    background-color: #373737;
    color: #E9E9E9;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;

    .MuiSvgIcon-root{
      width: 12px;
      height: 12px;
      color: #8C8E95;
    }
  }
`;

export const TabsetList = styled(Tabs)`
  border-bottom: 1px solid #373737;
  margin-bottom: 20px;

  .MuiTabs-indicator{
    display: none;
  }
`;

export const TabButton = styled(Tab)`
  line-height: 20px;
  font-size: 14px;
  color: #575757;
  padding: 13px 12px;
  text-align: left;
  text-transform: uppercase;
  min-width: 1px;
  min-height: 1px;
  max-width: none;
  letter-spacing: 0.02857em;
  border-bottom: 2px solid transparent;

  &:hover,
  &.Mui-selected{
    color: rgb(227, 192, 80);
    border-bottom-color: rgb(227, 192, 80);
  }
`;