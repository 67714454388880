import { useEffect, useState } from "react";
import { Wrapper, ButtonPrimary, ButtonSecondary } from "./style";

const StickyButtons = ({buttonPrimaryLabel, buttonSecondaryLabel, refferOnTouch} : any) => {
  return(
    <Wrapper>
      {buttonPrimaryLabel &&
        <ButtonPrimary type="button" onClick={() => refferOnTouch()}>{buttonPrimaryLabel}</ButtonPrimary>
      }
      {buttonSecondaryLabel &&
        <ButtonSecondary type="button" onClick={() => window.location.href = '/lead'}>{buttonSecondaryLabel}</ButtonSecondary>
      }
    </Wrapper> 
)};

export default StickyButtons;