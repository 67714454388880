import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Wrapper, Subtitle, StatusText } from "./style";
import StatisticsActionsDropdown from "components/StatisticsActionsDropdown";
import GraphWidget from "components/GraphWidget";
import { SetStateAction } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { dateHelper, usdDollar } from "utils/helper";

export default function StatisticsDashboardTable({ data }: any) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table aria-label="reports table">
          <TableHead>
            <TableRow>
              <TableCell>Campaign</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Campaign Start Date</TableCell>
              <TableCell>Campaign End Date</TableCell>
              <TableCell>Organization Strategy</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Spend</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Impressions</TableCell>
              <TableCell>CTR</TableCell>
              <TableCell>CPC</TableCell>
              {/* <TableCell>
                CPM
              </TableCell> */}
              {/* <TableCell>&nbsp;</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ _airbyte_data: item }: any) => (
              <tr>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/campaign-drill/${item.campaign_id}`)
                  }
                  component="th"
                  scope="row"
                >
                  {item.campaign_name}
                  {/* <Subtitle
                  variant="caption"
                >
                  04 Jan 2024
                </Subtitle> */}
                </TableCell>
                <TableCell>{item.channeltype}</TableCell>
                <TableCell>{item.channeltype}</TableCell>
                <TableCell>
                  <StatusText variant="caption" className="success">
                    {item.status}
                  </StatusText>
                </TableCell>
                <TableCell>
                  <Subtitle variant="caption">
                    {dateHelper(item.campaign_start_date)}
                  </Subtitle>
                </TableCell>
                <TableCell>
                  <Subtitle variant="caption">
                    {dateHelper(item.campaign_end_date)}
                  </Subtitle>
                </TableCell>
                <TableCell>{item.strategy}</TableCell>
                <TableCell>{usdDollar(item.totalbudget || 0)}</TableCell>
                <TableCell>{usdDollar(item.cost || 0)}</TableCell>
                <TableCell>{item.clicks || 0}</TableCell>
                <TableCell>{item.impressions || 0}</TableCell>
                <TableCell>
                  {item.clicks && item.impressions ? ((item.clicks / item.impressions) * 100).toFixed(2) + "%" :
                    "-"}
                </TableCell>
                <TableCell>{usdDollar(item.cost / item.clicks || 0)}</TableCell>
                {/* <TableCell>{usdDollar((item.cost / item.impressions) * 1000) || 0}</TableCell> */}
                {/* <TableCell>
                  <StatisticsActionsDropdown />
                </TableCell> */}
              </tr>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* empty table below */}
      {/* <TableContainer component={Paper}>
        <Table aria-label="reports table" className="--empty-data">
          <TableHead>
            <TableRow>
              <TableCell>Campaign</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Campaign Start Date</TableCell>
              <TableCell>Campaign End Date</TableCell>
              <TableCell>Organization Strategy</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Spend</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Impressions</TableCell>
              <TableCell>CTR</TableCell>
              <TableCell>CPC</TableCell>
              <TableCell>CPM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" colSpan={14}>
                <EmptyContainer>
                  <AlignWrapper>
                    <DescriptionText
                      variant="body1"
                    >
                      This is where the data will be displayed after the ads campaign has been launched
                    </DescriptionText>
                    <ButtonPrimary type="button">Launch campaign <ButtonArrowIcon /></ButtonPrimary>
                  </AlignWrapper>
                </EmptyContainer>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
      {/* <GraphWidget /> */}
    </Wrapper>
  );
}
