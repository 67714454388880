import { styled, Box, Typography, Button } from '@mui/material';

export const Wrapper = styled('div')`
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 24px;

  .MuiTableContainer-root{
    background-color: transparent;
    color: #E9E9E9;
  }

  table{
    min-width: 1160px;
    width: 100%;

    &.--empty-data{
      min-width: 576px;

      tbody{

        th{
          height: 300px;
        }
      }
    }

    thead{

      th{
        color: #696969;
        font-size: 14px;
      }
    }

    td,
    th{
      color: #E9E9E9;
      font-size: 14px;
      padding: 10px 17px;
      border: 0;
      vertical-align: top;

      @media (min-width: 1200px){
        font-size: 16px;
        padding: 16px 27px;
      }
    }
  }
`;

export const Subtitle = styled(Typography)`
  color: #8C8E95;
  font-size: inherit;
  display: block;
`;

export const StatusText = styled(Typography)`
  color: #575757;
  font-size: inherit;
  display: block;

  &.success{
    color: #11B585;
  }

  &.error{
    color: #E64135;
  }

  &.default{
    color: #E3C050;
  }
`;

export const DescriptionText = styled(Typography)`
  font-size: 16px;
  line-height 1.5;
  color: #C0C0C0;
`;

export const AlignWrapper = styled(Box)`
  width: 100%;
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
`;

export const EmptyContainer = styled(Box)`
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonPrimary = styled(Button)`
  margin-top: 24px;
  min-width: 108px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;