import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { getStripe } from 'utils/strip';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const StripeForm = ({ onClose }: any) => {

  return (
    <Elements stripe={getStripe()}>
      <CheckoutForm onClose={onClose} />
    </Elements>
  );
};