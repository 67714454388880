import { FormCol, FormHeading } from "./style"
import { DesignSlider } from "components/DesignSlider";

export const DesignPreview = ({ data }: any) => {
	const Headline = data("Headline")
	const Description = data("Description")
	const DestinationURL = data("DestinationURL")

	if (!Headline && !Description) return null

	return (
		<FormCol>
			<FormHeading
				variant="h2"
			>
				Your Design
			</FormHeading>
			<DesignSlider Headline={Headline} Description={Description} DestinationURL={DestinationURL} />
		</FormCol>
	)
}