import { PageWrapper } from "./style";
import CampaignDashboardSection from "components/CampaignDashboardSection";
const CampaignDashboard = () => {
  return (

    <div className="sixads-content">
      <div className="sixads-adss">
        <PageWrapper>
          <CampaignDashboardSection pTop="91px" pBottom="91px" />
        </PageWrapper>
      </div>
    </div>
  );
};
export default CampaignDashboard;
