import { Select, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BoxHeading, BoxWrapper, ButtonPrimary, CustomDivider, FootNote, FormCol, FormField, FormGroup, FormRow, Heading, HeadingWrapper, LabelHeading, PrifileIcon, SubTitle, TextPara, Title, TitleWrapper, UsersTableContainer, Wrapper, FormSelectField, TabsetList, TabButton } from "./style";
import { Controller, useForm } from "react-hook-form";
import baseApi from "api/baseApi";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "context";
import { role } from "utils/helper";

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; data: any; }) {
  const { children, value, index, data, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <UsersTableContainer>
          <Table aria-label="reports table">
            <TableHead>
              <TableRow>
                <TableCell>S.no</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((member: any, index: number) =>
                <TableRow key={member.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <PrifileIcon
                    >
                      {member.email[0]}
                    </PrifileIcon>
                  </TableCell>
                  <TableCell>
                    <Title
                      variant="caption"
                    >
                      {member.email}
                    </Title>
                  </TableCell>
                  <TableCell>{role(member.role)}</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </UsersTableContainer>
      )}
    </div>
  );
}

export default function MemberTabpanel({ }: any) {
  const [user, loading] = useAuthState(auth);
  const [members, setMembers] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { org } = useContext(AppContext);
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      role: 'member',
      email: ''
    }
  })
  const onSubmit = async (data: any) => {
    const res = await baseApi.get('org/orgList/' + user?.email)

    if (data.email) {
      baseApi.post('org/addUserToOrg', {
        orgName: org.id,
        email: data.email,
        // role: data.role
      }).then((res) => {
        setRefetch((prev) => !prev)
        toast.success(res.data.message)
      }).catch((err) => {
        toast.error(err.response.data.error)
      })
    }
  };

  const [value, setValue] = React.useState(0);
  const handleTabsChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  useEffect(() => {
    baseApi.get(`/org/${org.id}/users`).then((res) => setMembers(res.data)).catch((err) => console.log(err))
  }, [org, refetch])

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading
          variant="h2"
        >
          Members
        </Heading>
        <TextPara
          variant="body1"
        >
          Manage and add Team Members
        </TextPara>
      </HeadingWrapper>
      <BoxWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BoxHeading
            variant="h3"
          >
            Add new members
          </BoxHeading>
          <CustomDivider />
          <FormRow>
            <FormCol>
              <FormGroup>
                <LabelHeading
                  variant="h4"
                >
                  Enter new members by email address
                </LabelHeading>
                <FormField hiddenLabel placeholder="Enter an email address" {...register('email')} />
              </FormGroup>
            </FormCol>
            <FormCol>
              <FormGroup>
                <LabelHeading
                  variant="h4"
                >
                  Role
                </LabelHeading>
                <FormSelectField>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        onChange={onChange}
                      >
                        <MenuItem
                          value="member"
                        >
                          Member
                        </MenuItem>
                        {/* <MenuItem
                          value="admin"
                        >
                          Admin
                        </MenuItem>
                        <MenuItem
                          value="owner"
                        >
                          Owner
                        </MenuItem> */}
                      </Select>
                    )} />
                </FormSelectField>
              </FormGroup>
            </FormCol>
          </FormRow>
          <CustomDivider />
          <FootNote>
            <TextPara
              variant="body1"
            >
              Owners possess the ability to manage teams, billing, reset the organization, and events. Admins have the ability to manage teams, billing, and events. Members have the ability to manage events.
            </TextPara>
            <ButtonPrimary type="submit">ADD</ButtonPrimary>
          </FootNote>
        </form>
      </BoxWrapper>
      <TabsetList
        value={value}
        onChange={handleTabsChange}
      >
        <TabButton label="Team Members" />
      </TabsetList>
      <TabPanel data={members} value={value} index={0}>
        Item One
      </TabPanel>
    </Wrapper>
  );
}