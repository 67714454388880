import LandingPage from "pages/Landing";
import Login from "pages/Login";
import { GetStartedGuide } from "pages/GetStartedGuide";
import { Navigate, Route, Routes } from "react-router-dom";
import OrderPage from "pages/OrderPage";
import HomePage from "pages/Home";
import CampaignDrill from "pages/CampaignDrill";
import MetaLead from "pages/MetaLead";
import CampaignDashboard from "pages/CampaignDashboard";
import Account from "pages/Account";
import Draft from "pages/Draft";

export const Authorized = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/get-started-guide" element={<GetStartedGuide />} />
    <Route path="/order" element={<OrderPage />} />
    <Route path="/callback" element={<Login />} />
    <Route path="/campaign-drill/:id" element={<CampaignDrill />} />
    <Route path="/campaign-dashboard" element={<CampaignDashboard />} />
    <Route path="/account" element={<Account />} />
    <Route path="/lead/:uuid" element={<MetaLead />} />
    <Route path="/lead/" element={<MetaLead />} />
    <Route path="/drafts" element={<Draft />} />
  </Routes>
);
