import { styled, Box, Typography, Divider } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 12px 24px;
`;

export const Title = styled(Typography)`
  color: #8C8E95;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 8px;
`;

export const Count = styled(Typography)`
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  word-break: break-word;

  @media (min-width: 992px){
    font-size: 23px;
  }
  @media (min-width: 1200px){
    font-size: 30px;
  }
`;