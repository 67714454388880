import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Wrapper, Subtitle, StatusText, EmptyContainer, DescriptionText, ButtonPrimary, AlignWrapper } from "./style";
import { useEffect, useState } from "react";
import axios from "axios";
import { usdDollar } from "utils/helper";

const StatisticsLoadedKeywordTable = ({data}: any) => {
  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table aria-label="reports table">
          <TableHead>
            <TableRow>
              <TableCell>Keyword</TableCell>
              <TableCell>Match Type</TableCell>
              <TableCell>Clicks</TableCell>
              <TableCell>Conversions</TableCell>
              <TableCell>Impressions</TableCell>
              <TableCell>CTR</TableCell>
              <TableCell>CPC</TableCell>
              <TableCell>CPI</TableCell>
              <TableCell>COST</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({_airbyte_data: item}: any) => 
            <TableRow>
              <TableCell component="th" scope="row">
                {item.keyword}
                {/* <Subtitle
                variant="caption"
              >
                Web - Retargeting
              </Subtitle> */}
              </TableCell>
              <TableCell>
                <StatusText
                  variant="caption"
                  className="success"
                >
                  {item.matchtype}
                </StatusText>
              </TableCell>
              <TableCell>
                <Subtitle
                  variant="caption"
                >
                  {item.clicks}
                </Subtitle>
              </TableCell>
              <TableCell>
                <Subtitle
                  variant="caption"
                >
                  {item.conversions}
                </Subtitle>
              </TableCell>
              <TableCell>{item.impressions}</TableCell>
              <TableCell>{((item.clicks / item.impressions ) * 100).toFixed(2) || 0}%</TableCell>
              <TableCell>{usdDollar(item.cost/item.clicks || 0) }</TableCell>
              <TableCell>{usdDollar(item.cost/item.impressions || 0) }</TableCell>
              <TableCell>{usdDollar(item.cost)}</TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* empty table below */}
      {/* <TableContainer component={Paper}>
      <Table aria-label="reports table" className="--empty-data">
        <TableHead>
          <TableRow>
            <TableCell>Campaign</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Campaign Start Date</TableCell>
            <TableCell>Campaign End Date</TableCell>
            <TableCell>Organization Strategy</TableCell>
            <TableCell>Budget</TableCell>
            <TableCell>Spend</TableCell>
            <TableCell>Clicks</TableCell>
            <TableCell>Impressions</TableCell>
            <TableCell>CTR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" colSpan={10}>
              <EmptyContainer>
                <AlignWrapper>
                  <DescriptionText
                    variant="body1"
                  >
                    This is where the data will be displayed after the ads campaign has been launched
                  </DescriptionText>
                  <ButtonPrimary type="button">Launch campaign</ButtonPrimary>
                </AlignWrapper>
              </EmptyContainer>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer> */}
    </Wrapper>
  );
}

export default StatisticsLoadedKeywordTable;