import { FormField, FormGroup, LabelHeading, FieldsColumnsWrapper, FieldCol, StatsTable, ButtonPrimary, ButtonWrapper, FundsModal, ModalContent, ModalHeading, ButtonDefault, ModalFoot, IconsWrapper, IconItem, ErrorText, ButtonRemove } from "../../pages/MetaLead/style";
import { Box, TableBody, TableCell, TableRow, TableFooter } from "@mui/material";

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import baseApi from "api/baseApi";
import { confirmPayment, createPaymentMethod } from "utils/strip";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { useContext } from "react";
import { AppContext } from "context";
import { usdDollar } from "utils/helper";
import { useLocation } from "react-router-dom";
import { SESSION_AUTH } from "utils/auth";

const CheckoutForm = ({ onClose }: any) => {
  const path = useLocation();
  const stripe: any = useStripe();
  const { universalData, balance, updateBalance } = useContext(AppContext)
  const elements: any = useElements();
  const { org } = useContext(AppContext);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const preventNegativeInput = (event: any) => {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const onSubmit = async (data: any) => {
    const toastId = toast.loading('Payment In Process')

    const paymentCreate = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
      billing_details: {
        email: universalData?.email,
      },
    })

    if (paymentCreate.error) {
      toast.dismiss(toastId)
      return toast.error(paymentCreate.error.message)
    }

    try {
      const req = await baseApi.post("/api/process-payment/", {
        OrgName: org.id,
        OrgId: org.id,
        orgCode: org.orgName,
        Email: universalData?.email,
        amount: data.amount,
        currency: "usd",  // Currency code
        paymentMethodId: paymentCreate.paymentMethod.id,
      })

      toast.dismiss(toastId)
      toast.success(req.data.message);
      updateBalance()
      onClose();
      return

      // const result = await stripe?.confirmPayment(
      //   {
      //     clientSecret: req.data.clientSecret,
      //     confirmParams: {
      //       return_url: document.URL,
      //     },
      //     redirect: 'if_required'
      //   }
      // )
      // if (req.error) {
      //   // Show error to your customer (for example, payment details incomplete)
      //   toast.error(result.error.message);
      // } else {

      // }
    }
    catch (e) {
      toast.dismiss(toastId)
      toast.error('Internal Server Error')
      return
    }
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#fff",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    lineHeight: "25px",
    fontSmoothing: "antialiased",
    padding: "10px",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#8c8e95",
    },
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeading
          variant="h3"
        >
          Add Funds
        </ModalHeading>
        <FormGroup className="card-group">
          <LabelHeading
            variant="h3"
          >
            Amount
          </LabelHeading>
          <FormField id="ldf-Amount"
            type="number" min="0"
            onKeyPress={preventNegativeInput}
            {...register('amount', { required: true, min: { value: 1, message: "Amount must be greater than 0" } })}
            onWheel={(e: any) => e.target.blur()}
            hiddenLabel />
          {errors.amount && <p>{errors.amount.message}</p>}
        </FormGroup>
        <FormGroup>
          <LabelHeading
            variant="h3"
          >
            Credit Card Number
          </LabelHeading>
          <CardNumberElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
          />
        </FormGroup>
        <FieldsColumnsWrapper>
          <FieldCol>
            <FormGroup>
              <LabelHeading
                variant="h3"
              >
                Expiry Date
              </LabelHeading>
              <CardExpiryElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </FormGroup>
          </FieldCol>
          <FieldCol>
            <FormGroup>
              <LabelHeading
                variant="h3"
              >
                CVC
              </LabelHeading>
              <CardCvcElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </FormGroup>
          </FieldCol>
        </FieldsColumnsWrapper>
        <StatsTable>
          <TableBody>
            <TableRow>
              <TableCell align="left">Your Wallet</TableCell>
              <TableCell align="right">{usdDollar(balance)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Additional Funds</TableCell>
              <TableCell align="right">{usdDollar(watch("amount") || 0)}</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="left">Your Wallet After Purchase</TableCell>
              <TableCell align="right">{usdDollar(parseFloat(watch('amount')) ? parseFloat(watch('amount')) + balance : balance)}</TableCell>
            </TableRow>
          </TableFooter>
        </StatsTable>
        <ModalFoot>
          <ButtonDefault type="button" onClick={() => onClose()}>cancel</ButtonDefault>
          <ButtonPrimary type="submit">ADD FUNDS</ButtonPrimary>
        </ModalFoot>
      </form>
    </Box>
  )
};

export default CheckoutForm;