import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const ChipDeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M2.60938 2.60876L9.3976 9.39699" stroke="#8C8E95" stroke-width="1.28571" stroke-linecap="round"/>
      <path fill="none" d="M2.60938 9.39636L9.3976 2.60814" stroke="#8C8E95" stroke-width="1.28571" stroke-linecap="round"/>
    </SvgIcon>
  );
};