import { useContext, useEffect, useState } from "react";
import MetaForm from "./MetaForm";
import { AppContext } from "context";
import baseApi from "api/baseApi";
import { useParams } from "react-router-dom";

const OrderPage = () => {
  const { uuid } = useParams();
  const { org } = useContext(AppContext)
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false)
    if (uuid) {
      baseApi.get(`/api/draft/${uuid}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err))
        .finally(() => setLoading(true))
    } else {
      setLoading(true)
    }
  }, [uuid])

  return (
    <div className="sixads-content">
      <div className="sixads-adss">
        {loading &&
          <MetaForm data={data} />
        }
      </div>
    </div>
  );
};

export default OrderPage;