import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { AnchorLink, ButtonDropdownWrapper, ButtonOpener, CourseMenu, DescriptionWrapper, TextPara, LogoWrapper, ButtonLink, ButtonWrapper } from './style';
import { UpDownIcon } from 'components/Icons/UpDownIcon';
import { TickIcon } from 'components/Icons/TickIcon';
import { PlusIcon } from 'components/Icons/PlusIcon';
import baseApi from 'api/baseApi';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, userLogout } from '../../firebase';
import avatar from "../../assets/images/avatar.png";
import { LogoutIcon } from 'components/Icons/LogoutIcon';
import { UserIcon } from 'components/Icons/UserIcon';
import { SESSION_AUTH } from 'utils/auth';

export default function ProfileDropdown({user}: any) {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH()
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <ButtonDropdownWrapper>
      <ButtonOpener id="profile-dropdown" aria-controls={`profile-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        <img src={user.profileImage || avatar} alt="" />
        <UserIcon />
      </ButtonOpener>
      <CourseMenu id={`profile-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <AnchorLink href="https://events.6ix.com/profile" target="_blank">
            Manage Profile
          </AnchorLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <AnchorLink href="https://events.6ix.com/account/setting" target="_blank">
            Account Settings
          </AnchorLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ButtonLink onClick={() => LOGOUT_USER_SESSION(isUserSession)} type="button">
            <LogoutIcon />
            Log Out
          </ButtonLink>
        </MenuItem>
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}