import axios from "axios";
import { GET_USER_AUTH_TOKEN } from "utils/auth";

// Set up a base URL for your API
const baseURL = process.env.REACT_APP_BASE_URL; // Replace with your actual API base URL

// Create an Axios instance with a base URL
const baseApi = axios.create({
  baseURL,
});

baseApi.interceptors.request.use(
  function (config) {
    config.headers.Authorization = GET_USER_AUTH_TOKEN();
    config.headers['app-name'] = process.env.REACT_APP_NAME;
    config.headers['app-signature'] = process.env.REACT_APP_SIGNATURE;
    config.headers["Content-Type"] = "application/json"
    // config.headers["Access-Control-Allow-Origin"] = process.env.REACT_APP_BASE_URL
    config.headers["Access-Control-Allow-Methods"] = "GET,HEAD,PUT,PATCH,POST,DELETE"


    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    return Promise.reject(error);
  }
);

export default baseApi;
