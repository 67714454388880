import { styled, Box, Button, Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

export const Wrapper = styled(Box)`
  background-color: #1F1F1F;
  padding: 8px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid rgb(43, 43, 43);

  @media (min-width: 768px){
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const NavWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-grow: 1;

  @media (min-width: 768px){
    gap: 10px;
  }
`;

export const Separator = styled(Box)`
  width: 1px;
  height: 24px;
  background-color: #373737;
`;

export const PictureWrapper = styled(Box)`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #373737;
  border: 1px solid #575757;
  margin: 0 auto 10px;

  img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }
`;

export const Logo = styled(Link)`
  line-height: 0;
  display: block;
  margin-left: 8px;
  
  img{
    max-width: 100%;
    height: auto;
  }
`;

export const ButtonNav = styled(Button)`
  color: #c0c0c0;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  min-width: 1px;
  padding: 3px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 8px;
  position: relative;
  padding: 0 !important;

  &:hover{
    color: #fff !important;
    background-color: transparent;
  }

  &.sidebar-opener--close{
    color: #fff !important;
    background-color: #131313 !important;
    border-color: #E3C050 !important;

    &:before{
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: default;
    }

    .icn-close{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: inherit;
      background-color: #131313;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .icn-close{
    display: none;

    .MuiSvgIcon-root{
      width: 18px;
      height: 18px;
    }
  }

  &,
  .icn-wrap{
    display: block;
  }

  .MuiSvgIcon-root{
    width: 40px;
    height: 40px;
  }
}
`;

export const ButtonOpener = styled(Button)`
  color: #8c8e95;
  border: 1px solid transparent;
  font-size: 8px;
  font-weight: 700;
  line-height: 1;
  min-width: 1px;
  padding: 5px 8px !important;
  border-radius: 8px;

  &:hover{
    color: #8C8E95;
    background-color: #2B2B2B;
    border-color: #2B2B2B;
  }

  &,
  .icn-wrap{
    display: block;
  }

  .icn-wrap{
    margin-bottom: 4px;
  }

  .MuiSvgIcon-root{
    color: #C0C0C0;
    width: 18px;
    height: 18px;
  }
}
`;

export const ActionsWrapper = styled(Box)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 5px;

  @media (min-width: 768px){
    gap: 10px;
  }

  #header-language-menu .MuiPaper-root.MuiPaper-elevation{
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.4) !important;
    margin-top: 0;
    border-radius: 11px;
    font-weight: 700;
    color: #e9e9e9;
    width: 500px;
    max-height: 300px;
  }
  
  #header-language-menu .header-language-box-menu-inner{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  #header-language-menu .MuiMenuItem-root{
    font-size: 14px;
    line-height: 1.35;
    word-break: break-word;
    white-space: normal;
    width: calc(50% - 3px);
  }
  
  #header-language-menu .MuiMenuItem-root:hover{
    background-color: #2b2b2b;
    color: #fff;
  }
  
  .header-language-box-top{
    color: #c0c0c0;
    font-size: 10px;
    gap: 6px;
    font-weight: 600;
    min-width: 1px;
    background-color: transparent;
    padding: 3px !important;
    height: 45px !important;
    width: 45px !important;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    text-align: center;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    letter-spacing: normal;
    box-shadow: none;
  }
  
  .header-language-box-top:hover{
    color: #e9e9e9;
    background-color: #1f1f1f;
    border-color: #E3C050;
    box-shadow: none;
  }
  
  .header-language-box-top > .MuiSvgIcon-root{
    width: 18px;
    height: 19px;
  }
  
  .header-language-box > .MuiSvgIcon-root{
    color: #e9e9e9;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  
  @media (min-width: 576px){
    #header-language-menu .MuiMenuItem-root{
      width: calc(33.333% - 4px);
    }
  }
  @media (min-width: 768px){
    .header-language-box-top{
      height: 50px !important;
      width: 50px !important;
    }
  }
`;

export const LoginButton = styled(`span`)`
  color: #c0c0c0;
  font-size: 10px;
  font-weight: 600;
  line-height: 0;
  min-width: 1px;
  background-color: transparent;
  padding: 3px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 11px;

  @media (min-width: 768px){
    height: 50px !important;
    width: 50px !important;
  }

  &:hover{
    color: #e9e9e9;
    background-color: #1f1f1f;
    border-color: #E3C050;
  }

  .icn-wrap{
    display: block;
    margin-top: -5px;
  }

  .MuiSvgIcon-root{
    width: 18px;
    height: 19px;
  }
`;

export const ProfileModal = styled(Dialog)`
  box-shadow: none;

  .MuiPaper-elevation{
    border: 1px solid #454648;
    border-radius: 6px;
    background: #2b2b2b;
    width: 490px;
  }
`;

export const ModalContent = styled(DialogContent)`
  padding: 24px 24px;
  text-align: center;
`;

export const EditPicButton = styled(Button)`
  color: #e9e9e9;
  font-size: 16px;
  line-height: 1;
  text-transform: none;
  font-weight: 400;
  min-width: 1px;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-bottom: 24px;

  .MuiSvgIcon-root{
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export const FormGroup = styled(Box)`
  margin-bottom: 10px;
`;

export const LabelHeading = styled(Typography)`
  display: block;
  color: #C0C0C0;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: left;
`;

export const FormField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const TextAreaField = styled(TextField)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 120px;
  font-size: 14px;
  line-height: 18px;
  color: #e9e9e9;

  textarea{
    padding: 0;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  > div{
    display: block;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  input{
    color: #e9e9e9;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;

export const ButtonWrapper = styled(Box)`
  margin-top: 24px;
`;

export const ButtonPrimary = styled(Button)`
  min-width: 108px;
  background-color: #000;
  color: #E9E9E9;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #E3C050;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 16px 36px;
  width: 100%;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }
`;

export const CustomSelect = styled(Select)`
  color: #e9e9e9;
  box-sizing: border-box;
  background-color: #131313;
  border: 1px solid #373737;
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 14px;
  line-height: 18px;

  input{
    padding: 8px 12px;
    color: #e9e9e9;
    font-size: 14px;
    line-height: 18px;
  }

  &:focus,
  &.Mui-focused{
    outline: none;
    border-color: #E3C050;
  }

  fieldset,
  > div{
    width: 100%;
    height: 100%;
  }

  fieldset{
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
`;