import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownloadPresentationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="30" rx="6" fill="#2B2B2B"/>
      <path fill="none" d="M22.0666 17.7433V20.1814C22.0666 20.5048 21.9168 20.8148 21.65 21.0434C21.3833 21.2721 21.0216 21.4005 20.6444 21.4005H10.6888C10.3116 21.4005 9.94988 21.2721 9.68316 21.0434C9.41644 20.8148 9.2666 20.5048 9.2666 20.1814V17.7433" stroke="#E3C050" stroke-width="1.52686" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M12.0098 14.0867L15.6669 17.7439L19.3241 14.0867" stroke="#E3C050" stroke-width="1.52686" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill="none" d="M15.667 17.7428V8.59998" stroke="#E3C050" stroke-width="1.52686" stroke-linecap="round" stroke-linejoin="round"/>
    </SvgIcon>
  );
};