import React, { useContext, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { AnchorLink, ButtonDropdownWrapper, ButtonOpener, CourseMenu, DescriptionWrapper, TextPara, LogoWrapper, ButtonLink, ButtonWrapper } from './style';
import { UpDownIcon } from 'components/Icons/UpDownIcon';
import { TickIcon } from 'components/Icons/TickIcon';
import { PlusIcon } from 'components/Icons/PlusIcon';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, createOrg, getOrgList, switchOrgList } from '../../firebase';
import { OfficeIcon } from 'components/Icons/OfficeIcon';
import { AppContext } from 'context';
import { useLocation, useNavigate } from 'react-router-dom';
import { SESSION_AUTH } from 'utils/auth';

export default function SelectorDropdown({ label, id }: any) {
  const { isUserSession, UPDATE_USER_SESSION } = SESSION_AUTH();
  const [orgList, setOrgList] = useState<any>();
  const { universalData, updateUsername } = useContext(AppContext);
  const [refetch, setRefetch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const orgCreate = () => {
    createOrg(universalData).then(() => setRefetch((prev) => !prev)).catch((err) => console.log(err))
  }

  const changeOrg = (item: any) => {
    if (item.accountId !== universalData.org.id) {
      switchOrgList(universalData, item.accountId).then((res) => {
        UPDATE_USER_SESSION({
          ...isUserSession,
          org: res?.data.data,
        })
      })
    }
  }

  useEffect(() => {
    if (universalData?.userId) {
      getOrgList(universalData).then((res) => setOrgList(res.data.data))
    }
  }, [universalData?.userId, refetch])

  return (
    <ButtonDropdownWrapper>
      <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        <LogoWrapper>
          {universalData?.org?.orgLogo ? (
            <img src={universalData?.org?.orgLogo} alt="logo" width={30} height={30} />
          ) : (
            <OfficeIcon />
          )}
        </LogoWrapper>
        <span className="text-span">
          {universalData?.org?.orgName}
        </span>
        <i className='icn-wrap'>
          <UpDownIcon />
        </i>
      </ButtonOpener>
      <CourseMenu id={`${label}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {orgList?.map((item: any, v: any) => <MenuItem onClick={handleClose}>
          <AnchorLink onClick={() => changeOrg(item)}>
            {/* <AnchorLink> */}

            <LogoWrapper>
              {item?.orgLogo ? (
                <img src={item?.orgLogo} alt="logo" width={30} height={30} />
              ) : (
                <OfficeIcon />
              )}
            </LogoWrapper>
            <DescriptionWrapper className="DescriptionWrapper">
              <TextPara
                variant="body1"
              >
                {item?.orgName}
              </TextPara>
            </DescriptionWrapper>
            {item?.accountId === universalData?.org?.id &&
              <i className='tick-icn'>
                <TickIcon />
              </i>
            }
          </AnchorLink>
        </MenuItem>)}

        <MenuItem className="buttonWrapper" onClick={handleClose}>
          <ButtonWrapper>
            <ButtonLink onClick={() => orgCreate()} type="button">
              <PlusIcon />
              Create Org
            </ButtonLink>
          </ButtonWrapper>
        </MenuItem>
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}