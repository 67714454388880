import ContainerFluid from "components/ContainerFluid";
import { Section, PageHeading, HeadingWrapper, WidgetMaxWrapper, StatsWrapper, HeadRowWrapper, ExtraWrapper } from "./style";
import HeadlineWidget from "components/HeadlineWidget";
import StatisticColumn from "components/StatisticColumn";
import StatsExportDropdown from "components/StatsExportDropdown";
import StatisticsLoadedTable from "components/StatisticsLoadedTable";
import StatisticsLoadedKeywordTable from "components/StatisticsLoadedKeywordTable";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import baseApi from "api/baseApi";
import { dateHelper, usdDollar } from "utils/helper";
import { DesignSlider } from "components/DesignSlider";
import { AppContext } from "context";
import { toast } from "react-toastify";

const CampaignReportsSection = ({ pTop, pBottom }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>();
  const [sliderDetails, setSliderDetails] = useState<any>();
  const [keywordData, setKeywordData] = useState<any>();
  const item = data && data[0]._airbyte_data;
  const { org } = useContext(AppContext);

  useEffect(() => {
    baseApi.get(`/api/campaigndata/${id}`).then((res: any) => setData(res.data))

    baseApi.get(`/api/keyword/${id}`).then((res: any) => setKeywordData(res.data))
  }, [id])

  useEffect(() => {
    if (org && item) {
      baseApi.post('/api/getcampaigndetails/', {
        orgName: org.id,
        campaignname: item?.campaign_name
      })
        .then((res: any) => {
          setSliderDetails(res.data)
        })
        .catch((err: any) => {
          navigate('/campaign-dashboard')
          toast.error(err.response.data.error)
        })
    }
  }, [org, item])

  if (!sliderDetails) return null

  return (
    <Section
      className="CampaignReportsSection"
      sx={{
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }}
    >
      <ContainerFluid>
        <HeadingWrapper>
          <PageHeading
            variant="h1"
          >
            {item?.campaign_name}
          </PageHeading>
        </HeadingWrapper>
        <WidgetMaxWrapper>
          <DesignSlider
            preview={false}
            Headline={sliderDetails?.Headline}
            DestinationURL={sliderDetails?.DestinationURL}
            Description={sliderDetails?.Description}
          />
        </WidgetMaxWrapper>
        <HeadRowWrapper>
          <StatsWrapper>
            <StatisticColumn title="Status" count={item?.status} />
            {/* <StatisticColumn title="Locations" count={sliderDetails?.locations?.[0]} /> */}
            <StatisticColumn title="Start Date" count={dateHelper(item?.campaign_start_date)} />
            <StatisticColumn title="End Date" count={dateHelper(item?.campaign_end_date)} />
            <StatisticColumn title="Total Budget" count={usdDollar(item?.totalbudget || 0)} />
            <StatisticColumn title="Total Spent" count={usdDollar(item?.cost || 0)} />
            <StatisticColumn title="Budget Remaining" count={usdDollar((item?.totalbudget - item?.cost) || 0)} />
            <StatisticColumn title="Total Impressions" count={item?.impressions} />
            <StatisticColumn title="Total Clicks" count={item?.clicks} />
          </StatsWrapper>
          {/* <StatsExportDropdown id="stats-export" label="EXPORT" /> */}
        </HeadRowWrapper>
        <StatisticsLoadedKeywordTable data={keywordData} sliderDetails={sliderDetails} />
      </ContainerFluid>
    </Section>
  );
}

export default CampaignReportsSection;