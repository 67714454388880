import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AppRouter } from "routes";
import { store } from "store";
import { RegsiterUser } from "components/RegisterUser";
import HeaderNew from "components/common/HeaderNew";
import { Context } from "context";

const App = () => {
  return (
    <Provider store={store}>
      <Context>
        <RegsiterUser>
          <BrowserRouter>
            <div className="sixads">
              <HeaderNew />
              <AppRouter />
            </div>
          </BrowserRouter>
        </RegsiterUser>
      </Context>
    </Provider>
  );
};

export default App;
