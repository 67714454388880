import { loadStripe } from '@stripe/stripe-js';

const stripe = require('stripe')(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const createPaymentMethod = async (data: any) => {
  const paymentMethod = await stripe.paymentMethods.create({
    type: 'card',
    card: {
      number: data.card_number,
      exp_month: data.exp.split('/')[0],
      exp_year: data.exp.split('/')[1],
      cvc: data.cvc
    },
  });

  return paymentMethod;
}


export const confirmPayment = async (clientSecret: any) => {
  return await stripe.confirmPayment(
    clientSecret
  )
}

let stripePromise: any;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
  }
  return stripePromise;
};
